import React, { useEffect, useState } from 'react';
import { Table, Message, Statistic, StatisticValue } from 'semantic-ui-react';
import { sanitizeString } from '../displayHelpers';  // Make sure the function is correctly named and imported

const APPLICATION_STATUS_COLOR = '#add8e6'
const COMPANY_TYPE_COLOR = '#90ee90'
const TIME_SINCE_COLOR = '#ddd'

const ApplicationInsights = () => {
  const [insights, setInsights] = useState({
    applicationCountsByStatus: {},
    applicationsByCompanyType: {},
    oneWeek: 0,
    twoWeeks: 0,
    otherWeeks: 0,
    total: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchInsights = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/jobs/applications/insights`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      const data = await response.json();
      setInsights(data);
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInsights();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const hasData = Object.keys(insights.applicationCountsByStatus).length > 0 || 
                  Object.keys(insights.applicationsByCompanyType).length > 0 

  return (
    hasData ? (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={Object.keys(insights.applicationCountsByStatus).length} style={{backgroundColor: APPLICATION_STATUS_COLOR}}>Application Status</Table.HeaderCell>
            <Table.HeaderCell colSpan={Object.keys(insights.applicationsByCompanyType).length} style={{backgroundColor: COMPANY_TYPE_COLOR}}>Company Type</Table.HeaderCell>
            <Table.HeaderCell colSpan="3" style={{backgroundColor: TIME_SINCE_COLOR}}>Time Since Application</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {Object.keys(insights.applicationCountsByStatus).map((status, idx) => (
              <Table.HeaderCell style={{backgroundColor: APPLICATION_STATUS_COLOR}} key={`status-header-${idx}`}>{sanitizeString(status)}</Table.HeaderCell>
            ))}
            {Object.keys(insights.applicationsByCompanyType).map((type, idx) => (
              <Table.HeaderCell style={{backgroundColor: COMPANY_TYPE_COLOR}} key={`type-header-${idx}`}>{sanitizeString(type)}</Table.HeaderCell>
            ))}
            <Table.HeaderCell style={{backgroundColor: TIME_SINCE_COLOR}}> Less 1 week</Table.HeaderCell>
            <Table.HeaderCell style={{backgroundColor: TIME_SINCE_COLOR}}>1-2 weeks</Table.HeaderCell>
            <Table.HeaderCell style={{backgroundColor: TIME_SINCE_COLOR}}>Other</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {Object.entries(insights.applicationCountsByStatus).map(([status, count], index) => (
              <Table.Cell key={`status-count-${index}`}><Statistic size="mini"><StatisticValue>{count}/{insights.total}</StatisticValue></Statistic></Table.Cell>
            ))}
            {Object.entries(insights.applicationsByCompanyType).map(([type, count], index) => (
              <Table.Cell key={`type-count-${index}`}><Statistic size="mini"><StatisticValue>{count}/{insights.total}</StatisticValue></Statistic></Table.Cell>
            ))}
            <Table.Cell><Statistic size="mini"><StatisticValue>{insights.oneWeek}/{insights.total}</StatisticValue></Statistic></Table.Cell>
            <Table.Cell><Statistic size="mini"><StatisticValue>{insights.twoWeeks}/{insights.total}</StatisticValue></Statistic></Table.Cell>
            <Table.Cell><Statistic size="mini"><StatisticValue>{insights.otherWeeks}/{insights.total}</StatisticValue></Statistic></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    ) : (
      <Message info>
        Add some job applications to see application insights.
      </Message>
    )
  );
};

export default ApplicationInsights;
