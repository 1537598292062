import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Dropdown, Checkbox, Popup, Icon } from 'semantic-ui-react';
import { generateProjectInvolvementRoleOptions } from '../displayHelpers';
import ProjectApplicationButton from './ProjectApplicationButton';
import CallToActionBanner from './CallToActionBanner';
import { allowFeature, fetchFlags } from '../feature_flags_service';

const ApplyToProjectModal = ({project, toggleApplicationModal, applicationModalOpen, application, handleInputChange, handleSubmitApplication}) => {
    const [flags, setFlags] = useState([]);
    const [solo, setSolo] = useState(false)

    useEffect(() => {
        fetchFlags(setFlags)
    }, [])
    return (
        <>
            <ProjectApplicationButton project={project} toggleApplicationModal={toggleApplicationModal}/>
            <Modal open={applicationModalOpen} onClose={() => toggleApplicationModal(project._id)}>
            <Modal.Header>Apply to Project</Modal.Header>
            <Modal.Content>
                { allowFeature(flags, 'resumes') ? <CallToActionBanner message={'Your chances of getting accepted are much higher if we have your resume. If you apply to a project, the project creator can view your resume irrespective of your resume reveal status.'} buttonLink={'/profile'} buttonText={'Add Resume'}/> : null }
                <Form>
                    <Form.Field>
                        <span>
                            <Checkbox
                                label="Participate Solo"
                                checked={solo}
                                onChange={() => setSolo(!solo)}
                                style={{ marginBottom: '10px' }}
                            />
                            <Popup
                                content={
                                <div>
                                    <p>There are no applications for solo participation. You will be able to self-pace and complete the project on your own time.</p>  
                                </div>
                                }
                                trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                            />
                        </span>
                    </Form.Field>
                <Form.Field>
                    <Dropdown
                    label="Select your role"
                    placeholder="Role"
                    name="role"
                    fluid selection
                    options={generateProjectInvolvementRoleOptions()}
                    value={application.role}
                    onChange={handleInputChange}
                    />
                </Form.Field>
                <Form.TextArea
                    label="Why Interested"
                    placeholder='Why are you interested in this project?'
                    name='whyInterested'
                    value={application.whyInterested}
                    onChange={handleInputChange}
                />
                <Form.Input
                    label="Time Commitment per Week"
                    placeholder='How many hours can you commit per week?'
                    name='timeCommitment'
                    type='number'
                    value={application.timeCommitment}
                    onChange={handleInputChange}
                />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => toggleApplicationModal(project._id)}>Cancel</Button>
                <Button positive onClick={() => handleSubmitApplication(project._id, solo)}>Submit Application</Button>
            </Modal.Actions>
            </Modal>
        </>
    )
}

export default ApplyToProjectModal;