import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input, Segment } from 'semantic-ui-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPasswordForm = () => {
  const query = useQuery();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState(''); // State for email
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const tokenFromUrl = query.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [query]);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!");
      return;
    }

    // Include the email in the API request
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, token, newPassword }),
      });

      if (response.ok) {
        toast.success('Password reset successfully!');
        // Additional actions on success (e.g., redirect to login page)
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Segment>
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
        <Form.Field>
          <label>Email</label>
          <Input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>New Password</label>
          <Input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Confirm New Password</label>
          <Input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Field>
        <Button type="submit" primary>Reset Password</Button>
      </Form>
      <ToastContainer position="top-center" />
    </Segment>
  );
};

export default ResetPasswordForm;
