import React, { useState, useEffect } from 'react';
import { Table, Button, Grid, Header, Segment, Label, Dropdown, Pagination, Divider, Checkbox } from 'semantic-ui-react';
import JobsModal from './JobsModal'; // Adjust the import path as necessary
import CallToActionBanner from './CallToActionBanner';

const CompanyTracker = ({isLoggedIn}) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wlbFilter, setWlbFilter] = useState('none');
  const [payFilter, setPayFilter] = useState('none');
  const [sizeFilter, setSizeFilter] = useState('none');
  const [hasJobsFilter, setHasJobsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [savedCompanies, setSavedCompanies] = useState([]);

  const isPaidMember = localStorage.getItem('isPro') === 'true';

  const wlbOptions = [
    { key: 'none', text: 'No selection', value: 'none' },
    { key: 'excellent', text: 'Excellent', value: 'excellent' },
    { key: 'average', text: 'Average', value: 'average' },
    { key: 'poor', text: 'Poor', value: 'poor' },
  ];

  const payOptions = [
    { key: 'none', text: 'No selection', value: 'none' },
    { key: 'excellent', text: 'Excellent', value: 'excellent' },
    { key: 'average', text: 'Average', value: 'average' },
    { key: 'poor', text: 'Poor', value: 'poor' },
  ];

  const sizeOptions = [
    { key: 'none', text: 'No selection', value: 'none' },
    { key: 'small', text: 'Small', value: 'small' },
    { key: 'medium', text: 'Medium', value: 'medium' },
    { key: 'large', text: 'Large', value: 'large' },
    { key: 'very_large', text: 'Very Large', value: 'very_large' },
  ];

  useEffect(() => {
    fetchCompanies();
    fetchSavedCompanies();
  }, [wlbFilter, payFilter, sizeFilter, hasJobsFilter, page]);

  const fetchCompanies = async () => {
    try {
      const queryParams = new URLSearchParams({
        work_life_balance_categorization: wlbFilter !== 'none' ? wlbFilter : '',
        compensation_and_benefits_categorization: payFilter !== 'none' ? payFilter : '',
        size: sizeFilter !== 'none' ? sizeFilter : '',
        hasJobs: hasJobsFilter,
        page: page.toString(),
        limit: '10'
      }).toString();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/jobs/premium?${queryParams}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        }
      );

      const data = await response.json();
      setCompanies(data.companies || []);
      setTotalResults(data.total);
      setTotalPages(Math.ceil(data.total / 10));
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchSavedCompanies = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/companies/savedCompanies`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        }
      );
      const data = await response.json();
      setSavedCompanies(data.companies);
    } catch (error) {
      console.error('Error fetching saved jobs:', error);
    }
  };

  const saveCompany = async (companyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/companies/saveCompany`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({ companyId })
        }
      );
      if (response.ok) {
        fetchSavedCompanies();
      } else {
        console.error('Error saving job');
      }
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  const openJobModal = (companyId) => {
    setSelectedCompanyId(companyId);
    setIsModalOpen(true);
  };

  const closeJobModal = () => {
    setSelectedCompanyId(null);
    setIsModalOpen(false);
  };

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  return (
    isPaidMember ? <div>
      <Grid columns={3} style={{ marginTop: '10px' }}>
        <Grid.Row>
          <Grid.Column>
            <label style={{ marginLeft: '5px' }}><strong>Work Life Balance</strong></label>
            <Dropdown
              placeholder="Select Work Life Balance"
              fluid
              selection
              options={wlbOptions}
              onChange={(e, { value }) => setWlbFilter(value)}
              value={wlbFilter}
            />
          </Grid.Column>
          <Grid.Column>
            <label style={{ marginLeft: '5px' }}><strong>Pay</strong></label>
            <Dropdown
              placeholder="Select Pay"
              fluid
              selection
              options={payOptions}
              onChange={(e, { value }) => setPayFilter(value)}
              value={payFilter}
            />
          </Grid.Column>
          <Grid.Column>
            <label style={{ marginLeft: '5px' }}><strong>Size</strong></label>
            <Dropdown
              placeholder="Select Size"
              fluid
              selection
              options={sizeOptions}
              onChange={(e, { value }) => setSizeFilter(value)}
              value={sizeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              label="Only show companies with jobs"
              checked={hasJobsFilter}
              onChange={() => setHasJobsFilter(!hasJobsFilter)}
            />
          </Grid.Column>
          <Grid.Column>
            <strong style={{ marginLeft: '15px' }}>Total Results: {totalResults}</strong>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Segment>
        <Header as="h3">Company List</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Salary</Table.HeaderCell>
              <Table.HeaderCell>Founded</Table.HeaderCell>
              <Table.HeaderCell>Website</Table.HeaderCell>
              <Table.HeaderCell>Career Growth</Table.HeaderCell>
              <Table.HeaderCell>Work Life Balance</Table.HeaderCell>
              <Table.HeaderCell>Compensation & Benefits</Table.HeaderCell>
              <Table.HeaderCell>Company Culture</Table.HeaderCell>
              <Table.HeaderCell>Management</Table.HeaderCell>
              <Table.HeaderCell>Size Category</Table.HeaderCell>
              <Table.HeaderCell>Jobs</Table.HeaderCell>
              <Table.HeaderCell>Select</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {companies.map(company => (
              <Table.Row key={company._id}>
                <Table.Cell>{company.name}</Table.Cell>
                <Table.Cell>{company.size}</Table.Cell>
                <Table.Cell>{company.salary}</Table.Cell>
                <Table.Cell>{company.founded}</Table.Cell>
                <Table.Cell><a href={company.website} target="_blank" rel="noopener noreferrer">Website</a></Table.Cell>
                <Table.Cell>{company.career_growth_categorization}</Table.Cell>
                <Table.Cell>{company.work_life_balance_categorization}</Table.Cell>
                <Table.Cell>{company.compensation_and_benefits_categorization}</Table.Cell>
                <Table.Cell>{company.company_culture_categorization}</Table.Cell>
                <Table.Cell>{company.management_categorization}</Table.Cell>
                <Table.Cell>{company.size_category}</Table.Cell>
                <Table.Cell>
                  <Label as='a' color='blue' onClick={() => openJobModal(company._id)}>
                    Jobs {company.jobCount}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <Button 
                    primary={savedCompanies.includes(company._id)}
                    onClick={() => saveCompany(company._id)}>
                    {savedCompanies.includes(company._id) ? 'Unselect' : 'Select'}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div style={{ margin: '10px 0' }}>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Segment>
      <Divider />

      <Segment>
        <Header as="h3">Selected Companies</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Salary</Table.HeaderCell>
              <Table.HeaderCell>Founded</Table.HeaderCell>
              <Table.HeaderCell>Website</Table.HeaderCell>
              <Table.HeaderCell>Career Growth</Table.HeaderCell>
              <Table.HeaderCell>Work Life Balance</Table.HeaderCell>
              <Table.HeaderCell>Compensation & Benefits</Table.HeaderCell>
              <Table.HeaderCell>Company Culture</Table.HeaderCell>
              <Table.HeaderCell>Management</Table.HeaderCell>
              <Table.HeaderCell>Size Category</Table.HeaderCell>
              <Table.HeaderCell>Jobs</Table.HeaderCell>
              <Table.HeaderCell>Select</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {savedCompanies.map(company => (
              <Table.Row key={company._id}>
                <Table.Cell>{company.name}</Table.Cell>
                <Table.Cell>{company.size}</Table.Cell>
                <Table.Cell>{company.salary}</Table.Cell>
                <Table.Cell>{company.founded}</Table.Cell>
                <Table.Cell><a href={company.website} target="_blank" rel="noopener noreferrer">Website</a></Table.Cell>
                <Table.Cell>{company.career_growth_categorization}</Table.Cell>
                <Table.Cell>{company.work_life_balance_categorization}</Table.Cell>
                <Table.Cell>{company.compensation_and_benefits_categorization}</Table.Cell>
                <Table.Cell>{company.company_culture_categorization}</Table.Cell>
                <Table.Cell>{company.management_categorization}</Table.Cell>
                <Table.Cell>{company.size_category}</Table.Cell>
                <Table.Cell>
                  <Label as='a' color='blue' onClick={() => openJobModal(company._id)}>
                    Jobs {company.jobCount}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <Button 
                    primary={savedCompanies.includes(company._id)}
                    onClick={() => saveCompany(company._id)}>
                    Unselect
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>

      <JobsModal
        companyId={selectedCompanyId}
        isModalOpen={isModalOpen}
        closeModal={closeJobModal}
        isLoggedIn={isLoggedIn}
      />
    </div> :
    <Segment>
      <CallToActionBanner message={'You need a Pro subscription to be accessing Company Insights!'} buttonLink={'/account'} buttonText={'Go to Subscriptions Page'} size={'small'} />
    </Segment>
  );
};

export default CompanyTracker;
