import React from 'react';
import { Message, Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const CallToActionBanner = ({ message, buttonText, buttonLink, size='mini' }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(buttonLink);
  };

  return (
    <Message size={size}>
      <Message.Content>
        <Message.Header>{message}</Message.Header>
        <Button size={size} primary onClick={handleButtonClick} style={{ marginTop: '1rem' }}>
          {buttonText}
          <Icon name='arrow right' />
        </Button>
      </Message.Content>
    </Message>
  );
};

export default CallToActionBanner;
