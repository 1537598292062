import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Modal, Header, Icon } from 'semantic-ui-react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentCheckoutForm = ({ open, onClose }) => {
  const handleSubscribe = async () => {
    const stripe = await stripePromise;

    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/payments/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });

    const session = await response.json();

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

    if (error) {
      console.error('Error redirecting to Stripe Checkout:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Header icon>
        <Icon name="credit card" />
        Subscribe to KhwajaLabs Pro
      </Header>
      <Modal.Content>
        <p>Subscribe to KhwajaLabs Pro for exclusive features and benefits. You will be charged <strong>$5.99 per month</strong>. You can cancel anytime.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={onClose}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button color="green" inverted onClick={handleSubscribe}>
          <Icon name="checkmark" /> Subscribe
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentCheckoutForm;
