// CopyToClipboard.js
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const CopyToClipboard = ({ copyLink }) => {
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(copyLink);
            toast.success('Copied text to clipboard!');
        } catch (err) {
            toast.error('Failed to copy the text: ' + err);
        }
    };

    return (
        <Button basic icon onClick={copyToClipboard} size='small' compact>
            <Icon name='share'/>
        </Button>
    );
};

export default CopyToClipboard;
