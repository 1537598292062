import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button, Label, Popup } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createLabel } from '../displayHelpers';


const Navbar = ({isLoggedIn, roles, handleRoleUpdate, membershipData}) => {
  const [role, setRole] = useState(null); // Example roles: Admin, User, Guest
  const location = useLocation(); // Get the current location
  const [activeItem, setActiveItem] = useState(location.pathname); // Set the active item based on the current path

  useEffect(() => {
    setActiveItem(location.pathname); // Update active item when the location changes
  }, [location])

  const hasAdminRole = () => {
    return roles.includes('admin')
  }
  useEffect(() => {
    if (roles && roles.length > 0) {
      setRole(roles[0]); // Set initial role or update role when roles prop changes
    }
  }, [roles]); // This effect depends on the roles prop

  const navigate = useNavigate();
  // Dummy functions for handling actions
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/login');
    window.location.reload()
  };

  return (
    <Menu sticky="top" secondary size="large" stackable>
      {isLoggedIn ? (
        <>
          {/* Left-aligned items (if any) can go here */}
          <Menu.Menu position="left">
          <Menu.Item as={Link} to="/" active={activeItem === '/'} style={{ padding: '10px 20px', border: 'none' }}>
              <img src={require('../assets/logo.png')} alt="Logo" style={{ height: '80px', width: '150px' }} />
              <Label color="blue" circular size="mini" style={{ position: 'absolute', bottom: 0, right: 0, transform: 'translate(-50%, -50%)' }}>
                BETA
              </Label>
            </Menu.Item>
          </Menu.Menu>

          {/* Right-aligned items */}
          <Menu.Menu position="right">
          <Menu.Item>
            {membershipData.membership_type === 'pro' ? createLabel('purple', 'PRO', 'shield') : null}
            <Popup
                content={
                  <div>
                    <p>Number of AI credits remaining. Use can use AI credits to auto-magically create project timelines.</p>
                  </div>
                }
                trigger={createLabel('pink', `${membershipData.ai_credits}`, 'magic')}
              />
          </Menu.Item>
          {hasAdminRole() && <Dropdown item text={`Role: ${role}`}>
            <Dropdown.Menu>
              {roles.map((roleItem, index) => (
                <Dropdown.Item key={index} onClick={() => 
                  {
                    setRole(roleItem)
                    handleRoleUpdate(roleItem)
                  }
                }>
                  {roleItem}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          }
            <Menu.Item as={Link} to="/account">
              Account
            </Menu.Item>
            <Menu.Item as={Link} to="/pricing">
              Pricing
            </Menu.Item>
            <Menu.Item>
              <Button primary onClick={handleLogout}>Log Out</Button>
            </Menu.Item>
          </Menu.Menu>
        </>
      ) : (
        <>
          {/* Left-aligned items (if any) can go here */}
          <Menu.Menu position="left">
          <Menu.Item as={Link} to="/" active={activeItem === '/'} style={{ padding: '10px 20px', border: 'none' }}>
              <img src={require('../assets/logo.png')} alt="Logo" style={{ height: '80px', width: '150px' }} />
              <Label color="blue" circular size="mini" style={{ position: 'absolute', bottom: 0, right: 0, transform: 'translate(-50%, -50%)' }}>
                BETA
              </Label>
            </Menu.Item>
          </Menu.Menu>


          {/* Right-aligned items */}
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/about" active={activeItem === '/about'}>About
            </Menu.Item>
            <Menu.Item as={Link} to="/register" active={activeItem === '/register'}>Register
            </Menu.Item>
            <Menu.Item as={Link} to="/login" active={activeItem === '/login'}>
              Log In
            </Menu.Item>
            <Menu.Item as={Link} to="/pricing">
              Pricing
            </Menu.Item>
          </Menu.Menu>
        </>
      )}
    </Menu>
  );
};

export default Navbar;
