import React, { useEffect, useState } from 'react';
import { Form, Button, Segment, Label, Header, Checkbox, Icon, Accordion } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createLabel } from '../displayHelpers';
import ReactMarkdown from 'react-markdown';

const Assessment = ({ questions, onSubmit }) => {
    const [answers, setAnswers] = useState({});

    const handleChange = (questionId, optionId) => {
        setAnswers({
            ...answers,
            [questionId]: optionId
        });
    };

    const allQuestionsAnswered = questions.length > 0 && questions.every(question => answers.hasOwnProperty(question.id));

    const handleSubmit = () => {
        onSubmit(answers);
    };

    return (
        <Segment>
            <Header as="h3">Assessment</Header>
            <Form>
                {questions.map(question => (
                    <Form.Field key={question.id}>
                        <label>{question.text}</label>
                        <Form.Group grouped>
                            {question.options.map(option => (
                                <Form.Field key={option.id}>
                                    <Checkbox
                                        radio
                                        label={option.text}
                                        name={question.id}
                                        value={option.id}
                                        checked={answers[question.id] === option.id}
                                        onChange={() => handleChange(question.id, option.id)}
                                    />
                                </Form.Field>
                            ))}
                        </Form.Group>
                    </Form.Field>
                ))}
                <Button 
                    onClick={handleSubmit} 
                    disabled={!allQuestionsAnswered} // Disable button if not all questions are answered
                >
                    Submit Answers
                </Button>
            </Form>
        </Segment>
    );
};

const CourseInterface = ({ projectId }) => {
    const [tasks, setTasks] = useState([]);
    const [currentTask, setCurrentTask] = useState(null);
    const [assessmentAvailable, setAssessmentAvailable] = useState(false);
    const [project, setProject] = useState({
        background: '',
        features: [],
        timeline: '',
    });
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [currentAssessmentQuestions, setCurrentAssessmentQuestions] = useState([]);

    const fetchProject = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/get_one/${projectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                setProject(responseData);
                fetchProjectDetails(responseData.project_timeline);
                setLoading(false);
            } else {
                setLoading(false);
                console.error('Failed to fetch project');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching project:', error);
        }
    };

    const fetchProjectDetails = async (projectTimelineId) => {
        if (!projectTimelineId) {
            setTasks([]);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline_for_course/${projectTimelineId}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch project details');
            
            const project_timeline = await response.json();

            setTasks(project_timeline.tasks.map(task => ({
                ...task,
                id: task._id,
                start: new Date(task.start),
                end: new Date(task.end),
                taskStatus: task.taskStatus,
                group: 1
            })));

        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    useEffect(() => {
        fetchProject();
    }, []);

    useEffect(() => {
        if (tasks.length) {
            const todoTask = tasks.find(task => task.taskStatus === 'To Do');
            setCurrentTask(todoTask);
        }
    }, [tasks]);

    const fetchAssessment = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${taskId}/fetchAssessment`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.ok) {
                const questions = await response.json();

                // Format the questions to be used in the Assessment component
                const formattedQuestions = questions.map(question => ({
                    id: question._id,
                    text: question.question,
                    options: question.answers.map(option => ({
                        id: option._id,
                        text: option.answer
                    }))
                }));

                setCurrentAssessmentQuestions(formattedQuestions);
                setAssessmentAvailable(true);
            } else {
                throw new Error('Failed to fetch assessment');
            }
        } catch (error) {
            console.error('Error fetching assessment:', error);
            toast.error('Error fetching assessment');
        }
    };

    const handleAssessmentSubmit = async (answers) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${currentTask.id}/submitAssessment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ answers })
            });

            const result = await response.json();

            if (response.ok && result.pass) {
                toast.success('Assessment passed!');
                // Refetch tasks and project details if the assessment is passed
                fetchProjectDetails(project.project_timeline);
                setAssessmentAvailable(false);
            } else {
                toast.error('Assessment failed. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting assessment:', error);
            toast.error('Error submitting assessment');
        }
    };

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    return (
        <Segment.Group>
            <Segment>
                <Header as="h3">Current Task</Header>
                {currentTask ? (
                    <div>
                    <p><strong>{currentTask.content}</strong></p>
                    <p>{currentTask.description}</p>
                    {currentTask.additionalContext && currentTask.courseTask && (
                        <>
                            <Segment>
                                {currentTask.courseTask.topics_material && (
                                    <div>
                                        <ReactMarkdown>{currentTask.courseTask.topics_material}</ReactMarkdown>
                                    </div>
                                )}
                            </Segment>
                            <Segment>
                                <i>Exercise or Demonstration for learning</i>
                                <ReactMarkdown>{currentTask.additionalContext}</ReactMarkdown>
                            </Segment>
                            <Segment>
                                {currentTask.courseTask.additional_context_material && (
                                    <div>
                                        <ReactMarkdown>{currentTask.courseTask.additional_context_material}</ReactMarkdown>
                                    </div>
                                )}
                            </Segment>
                        </>
                    )}
                    <Button onClick={() => fetchAssessment(currentTask._id)}>Take Assessment</Button>
                </div>
                ) : (
                    <p>No tasks available.</p>
                )}
            </Segment>
            {assessmentAvailable && (
                <Assessment
                    questions={currentAssessmentQuestions}
                    onSubmit={handleAssessmentSubmit}
                />
            )}
            <Segment>
                <Header as="h3">All Tasks Overview</Header>
                <Accordion styled fluid>
                    {tasks.map((task, index) => (
                        <React.Fragment key={task.id}>
                            <Accordion.Title
                                active={activeIndex === index}
                                index={index}
                                onClick={() => handleAccordionClick(index)}
                            >
                                <Icon name='dropdown' />
                                {task.content}{' '}{task.taskStatus === 'Done' ? createLabel('yellow', 'Complete', 'trophy') : null}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                                <p>{task.description}</p>
                                <Label>{task.topics_to_cover?.join(', ')}</Label>
                            </Accordion.Content>
                        </React.Fragment>
                    ))}
                </Accordion>
            </Segment>
        </Segment.Group>
    );
};

export default CourseInterface;
