import React, { useState, useEffect } from 'react';
import { Menu, Segment, Grid, Button, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

const NotificationsPage = ({fetchUnreadNotificationsCount}) => {
  const [notifications, setNotifications] = useState([]);
  const [activeNotification, setActiveNotification] = useState(null);
  const navigate = useNavigate();

  const fetchUnreadNotifications = async() => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/notifications`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      if (!response.ok) {
          console.error(`Error fetching notifications`)
          return
      };
      const result = await response.json()
      setNotifications(result.notifications); // Set to actual API response later
      if (result.notifications.length) setActiveNotification(result.notifications[0])
    return
  };

  useEffect(() => {
    fetchUnreadNotifications()
  }, []);

  const readNotification = async(notificationId) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/notifications/${notificationId}/read`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
    if (!response.ok) {
        console.error(`Error reading notifications`)
        return
    };
    fetchUnreadNotificationsCount()
  return
};

useEffect(() => {
  fetchUnreadNotifications()
}, []);

  const handleItemClick = (notificationId) => {
    setActiveNotification(notifications.find(n => n._id === notificationId));
    // Mark the notification as read when clicked, TODO: send API call
    setNotifications(notifications.map(notification =>
      notification._id === notificationId ? { ...notification, read: true } : notification
    ));
    readNotification(notificationId)
  };

  return (
    notifications.length > 0 ? <Grid>
      <Grid.Column width={4}>
        <Menu fluid vertical tabular>
          {notifications.map(notification => (
            <Menu.Item
              key={notification._id}
              name={notification._id}
              active={activeNotification._id === notification._id}
              onClick={() => handleItemClick(notification._id)}
              style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
            >
              {notification.title}
              {!notification.read && <span style={{ marginLeft: '5px', width: '8px', height: '8px', backgroundColor: 'red', borderRadius: '50%' }}></span>}
            </Menu.Item>
          ))}
        </Menu>
      </Grid.Column>

      <Grid.Column stretched width={12}>
        <Segment>
        {activeNotification ? activeNotification.message : null} <br/>
        {activeNotification ? <strong>{formatDate(activeNotification.createdAt)}</strong> : null}
        {activeNotification ? activeNotification.actionLink && (
        <Button primary style={{ marginLeft: '20px' }} onClick={() => navigate(activeNotification.actionLink)}>
            Check it out
        </Button>
        ) : null}
        </Segment>
      </Grid.Column>
    </Grid> :
    <Message info>
        No notifications to display, please check back later.
    </Message>
  );
};

export default NotificationsPage;

