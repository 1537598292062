import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import ProjectsList from './ProjectsList';
import ProjectApplications from './ProjectApplications';

const MyGarage = ({ currentTab}) => {
  const [activeTab, setActiveTab] = useState(currentTab || 'my_projects');

  const handleTabClick = (e, { name }) => setActiveTab(name);

  const renderActiveTabContent = () => {
    switch (activeTab) {
    case 'my_projects':
        return <ProjectsList mine={true}/>;
    case 'projectApplicationsReceived':
        return <ProjectApplications received={true}/>;
    case 'projectApplicationsSubmitted':
        return <ProjectApplications received={false}/>;
    default:
        return null;
    }
  };

  return (
    <>
     <Menu attached='top' tabular>
        <Menu.Item
          name='my_projects'
          active={activeTab === 'my_projects'}
          onClick={handleTabClick}
        >
          My Projects
        </Menu.Item>
        <Menu.Item
            name='projectApplicationsReceived'
            active={activeTab === 'projectApplicationsReceived'}
            onClick={handleTabClick}
            >
            Project Applications Received
        </Menu.Item>
        <Menu.Item
            name='projectApplicationsSubmitted'
            active={activeTab === 'projectApplicationsSubmitted'}
            onClick={handleTabClick}
            >
            Project Applications Submitted
        </Menu.Item>
      </Menu>
      <Segment attached='bottom'>
        {renderActiveTabContent()}
      </Segment>
    </>
  );
};

export default MyGarage;
