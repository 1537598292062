import React, { useState } from 'react';
import { Button, Segment, Divider, Form, Input, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import CompanyTable from './CompanyTable';

const CompanyAdmin = () => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleFileChange = (event) => {
        let selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "application/json") {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setIsFilePicked(true);
            setError(false);
        } else {
            setError(true);
            setFile(null);
            setIsFilePicked(false);
            setFileName("");
        }
    };

    const handleSubmit = async (event, action = 'upload') => {
        event.preventDefault();
        setLoading(true);
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            const endpoint = action === 'upload' ? 'upload' : 'upload_and_refresh';
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/companies/${endpoint}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                    body: formData,  // formData will be the payload
                });

                if (response.ok) {
                    setFile(null);
                    setFileName('');
                    setIsFilePicked(false);
                    setError(false);
                    toast.success('Successfully uploaded file!');
                } else {
                    console.error('Failed to upload file');
                    setError(true);
                    toast.error('There was an error uploading the file');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('There was an error uploading the file');
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Segment loading={loading}>
            <Form onSubmit={(e) => handleSubmit(e, 'upload')} error={error}>
                <Form.Field>
                    <Input
                        type="file"
                        accept=".json"
                        onChange={handleFileChange}
                        label="Upload JSON"
                        placeholder="Upload your .json file"
                    />
                </Form.Field>
                {isFilePicked && (
                    <Message>
                        <Message.Header>File Selected:</Message.Header>
                        <p>{fileName}</p>
                    </Message>
                )}
                {error && (
                    <Message error header="File Error" content="Please upload a valid JSON file." />
                )}
                <Button type="submit" disabled={!isFilePicked}>Upload</Button>
                <Button type="button" onClick={(e) => handleSubmit(e, 'upload_and_refresh')} disabled={!isFilePicked}>Upload and Refresh</Button>
            </Form>
            <Divider />
            <CompanyTable />
        </Segment>
    );
};

export default CompanyAdmin;
