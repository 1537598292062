import React, { useState } from 'react';
import { Button, Segment, Divider, Message, Form, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModal from './ForgotPasswordModal';
import { toast } from 'react-toastify';
import { auth, provider } from '../firebase-config';
import { signInWithPopup } from "firebase/auth";


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
        const result = await signInWithPopup(auth, provider);
        const token = result.user.accessToken;

        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ idToken: token }),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('userId', data.user._id);
          localStorage.setItem('isPro', data.user.membership_type === 'pro');
          toast.success('Login successful!');
          navigate('/');
          window.location.reload();
        } else {
          throw new Error(data.message || 'Failed to login with Google');
        }
    } catch (error) {
        console.error('Login failed:', error);
        setError(error.message || 'Google login failed. Please try again.');
        toast.error(error.message || 'Google login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Login failed');
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('userId', data.user._id);
      localStorage.setItem('isPro', data.user.membership_type === 'pro');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to log in. Please check your credentials and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPasswordSubmit = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/forgot-password/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      toast.success('Check your email for password reset instructions!');
    } else {
      toast.error('Failed to send password reset instructions');
    }
  };

  return (
    <Segment padded loading={isLoading}>
      <Button basic color="blue" onClick={handleGoogleSignIn}><Icon name="google"/>Login with Google</Button>
      <Divider horizontal>Or</Divider>
      <Form onSubmit={handleLoginSubmit}>
        <Form.Field>
          <label>Email</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </Form.Field>
        <Button type="submit" primary fluid>Login with Email</Button>
        <ForgotPasswordModal onSubmit={handleForgotPasswordSubmit} />
      </Form>
      {error && <Message error content={error} />}
    </Segment>
  );
};

export default Login;
