import React, { useEffect, useState } from 'react';
import { Button, Form, Segment, Divider, Message, Dropdown, Modal, Checkbox, Label, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth, provider } from '../firebase-config';
import { signInWithPopup } from "firebase/auth";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/


const Register = () => {
    const [flags, setFlags] = useState([]);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullname, setFullname] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false); // Add this line
    const [modalOpen, setModalOpen] = useState(false); // Add this line for modal control
    // Password control checks
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const navigate = useNavigate();

    const fetchFlags = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
      {
        headers: {
            'Content-Type': 'application/json',
          },
      }
      );
      const data = await response.json();
      setFlags(data);
    };

    useEffect(() => {
      fetchFlags()
    },[])

    const handleRoleChange = (e, { value }) => setSelectedRoles(value);

    const handleGoogleSignUp = async () => {
      try {
          const result = await signInWithPopup(auth, provider);
          const token = result.user.accessToken

          // Optional: Send token to backend for verification and to create a new user record
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/google/signup`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ idToken: token, email: result.user.email })
          });

          const data = await response.json();
          if (response.ok) {
              toast.success('Registration successful!');
              navigate('/login');
          } else {
              toast.error(data.message || 'Registration failed');
          }
      } catch (error) {
          console.error('Error during Google sign-up:', error);
          toast.error('Google sign-up failed. Please try again.');
      }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, fullname, roles: selectedRoles }),
      });

      if (response.ok) {
        toast.success('Registration successful! Please check your email for verification steps!');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        toast.error('Failed to register. Please contact reeremote@gmail.com if issue persists.');
      }

      const data = await response.json();
      console.log('Registration successful:', data);
      navigate('/login');
      // Redirect the user or show a success message
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error.message || 'An error occurred during registration');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = ({ name, value }) => {
    if (name === 'password') {
      setPassword(value);
      const strength = passwordStrengthRegex.test(value) ? 'Strong' : 'Weak';
      setPasswordStrength(strength);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setPasswordsMatch(password === value);
    } else if (name === 'email') {
      setEmail(value);
      // Check if the email matches the regex
      const isValid = emailRegex.test(value);
      setIsEmailValid(isValid);
      if (!isValid) {
        // Set an error message specifically for invalid email format
        setError('Invalid email format');
      } else {
        // Clear the email error if the email becomes valid
        setError(prevError => prevError === 'Invalid email format' ? '' : prevError);
      }
    } else if (name === 'fullname') {
      setFullname(value);
    }
  
    // General error reset if user starts typing again in other fields
    if (name !== 'email') {
      setError('');
    }
  };
  

  return (
    <Segment padded>
      <Form onSubmit={handleSubmit} error={!!error}>
        <Button basic color="blue" onClick={handleGoogleSignUp}><Icon name="google"/> Sign up with Google</Button>
        <Divider horizontal>Or</Divider>
        <Form.Field>
          <label>Email</label>
          <input
            placeholder="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => handleInputChange(e.target)}
            required
          />
          {!isEmailValid && (
            <Label basic color="red" pointing>
              Invalid email format
            </Label>
          )}
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => handleInputChange(e.target)}
            required
          />
          {password && (
            <Label
              color={passwordStrength === 'Strong' ? 'green' : 'red'}
              pointing
              style={{ marginTop: '5px' }}
            >
              {passwordStrength === 'Strong' ? 'Password Strength: Strong' : (
                <>
                  Password Strength: Weak
                  <p>Please ensure your password:</p>
                  <ul>
                    <li>Is at least 8 characters long</li>
                    <li>Includes at least one lowercase letter (a-z)</li>
                    <li>Includes at least one uppercase letter (A-Z)</li>
                    <li>Contains at least one number (0-9)</li>
                    <li>Contains at least one special character (@$!%*?&)</li>
                  </ul>
                </>
              )}
            </Label>
          )}
        </Form.Field>
        <Form.Field>
          <label>Confirm Password</label>
          <input
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => handleInputChange(e.target)}
            required
          />
          {!passwordsMatch && confirmPassword && (
            <Label basic color="red" pointing>
              Passwords do not match
            </Label>
          )}
        </Form.Field>
        <Form.Field>
          <label>Full Name</label>
          <input
            placeholder="Full Name"
            type="text"
            name="fullname"
            value={fullname}
            onChange={(e) => handleInputChange(e.target)}
            required
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="I understand by signing up I agree to terms and conditions"
            onChange={(e, data) => setTermsAccepted(data.checked)}
          />
        </Form.Field>
        <Button onClick={() => setModalOpen(true)}>View Terms and Conditions</Button>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <Modal.Header>Terms and Conditions</Modal.Header>
          <Modal.Content>
          <p><strong>Terms and Conditions for KhwajaLabs (An interest of RemoteRee)</strong></p>
          <p>Last updated: 04/01/2024</p>
          <p>Welcome to KhwajaLabs, a platform designed to empower job seekers by allowing candidates to track job applications and participate in group projects. Please read these Terms and Conditions ("Terms") carefully before using the [Your App Name] application ("Service") operated by [Your Company Name] ("us", "we", or "our").</p>
          <p><strong>Acceptance of Terms</strong></p>
          <p>By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you do not have permission to access the Service.</p>
          <p><strong>Service Provision</strong></p>
          <p>The Service allows you to:

Track your job applications.
Participate in group projects with other candidates.
Privacy Notice
Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your information. By using our Service, you consent to the data practices described in our Privacy Policy.</p>
          <p><strong>User Obligations</strong></p>
          <p>You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Service.</p>
          <p><strong>Intellectual Property</strong></p>
          <p>The Service and its original content, features, and functionality are and will remain the exclusive property of KhwajaLabs and its licensors.</p>
          <p><strong>User-Generated Content</strong></p>
          <p>You may be able to submit content through the Service. You retain all rights to any content you submit, post, or display on or through the Service and are responsible for protecting those rights.</p>
          <p><strong>Compliance with COPPA</strong></p>
          <p>Our Service does not address and is not available to anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.</p>
          <p><strong>Changes to Terms</strong></p>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will try to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p><strong>Contact Us</strong></p>
          <p>If you have any questions about these Terms, please contact us at reeremote@gmail.com.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)} positive>
            Close
          </Button>
        </Modal.Actions>
        </Modal>
        {error && <Message error content={error} />}
        <Button
          type="submit"
          primary
          fluid
          loading={isLoading}
          disabled={!termsAccepted}
          style={{ margin: '20px auto', display: 'block', padding: '10px' }} // Add this line for inline styles
        >
          Register
        </Button>
      </Form>
    </Segment>
  );
};

export default Register;
