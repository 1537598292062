import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import MenuBar from '../MenuBar';
import UsersTable from './UsersTable';
import AdminProjectsTable from './AdminProjectsTable';

const AdminConsole = ({ isLoggedIn, currentRole, flags }) => {
  const [activeTab, setActiveTab] = useState('users');

  const handleTabClick = (e, { name }) => setActiveTab(name);

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'users':
        return <UsersTable />;
      case 'projects':
        return <AdminProjectsTable />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={true} />}
      <Segment style={{ overflow: 'auto', maxHeight: 'calc(100vh - 120px)' }}>
        <Menu attached='top' tabular stackable>
          <Menu.Item
            name='users'
            active={activeTab === 'users'}
            onClick={handleTabClick}
          >
            Users
          </Menu.Item>
          <Menu.Item
            name='projects'
            active={activeTab === 'projects'}
            onClick={handleTabClick}
          >
            Projects
          </Menu.Item>
        </Menu>

        <Segment attached='bottom'>
          {renderActiveTabContent()}
        </Segment>
      </Segment>
    </>
  );
};

export default AdminConsole;
