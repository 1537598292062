import { Form, Input } from 'semantic-ui-react';
import React, { useState } from 'react';

const MAX_TOPICS = 10;

const TopicInput = ({ onAddTopic, topics }) => {
    const [inputValue, setInputValue] = useState('');
  
    const handleAddTopic = (e) => {
      e.preventDefault(); // Prevent form submission
      if (inputValue && topics.length < MAX_TOPICS) {
        onAddTopic(inputValue);
        setInputValue(''); // Clear input after adding
      }
    };
  
    return (
      <Form.Field>
        <Input
          placeholder="Enter a topic"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          action={{
            color: 'teal',
            icon: 'add',
            onClick: handleAddTopic,
            content: 'Add'
          }}
        />
      </Form.Field>
    );
  };

  export default TopicInput