import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Input, Dropdown, Modal, Icon, Popup, Checkbox, Rating, ButtonGroup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import "../App.css"
import { companyTypeToColor, createLabel, generateCompanyTypeOptions, generateStatusOptions, mapCompanyTypeToDisplay, mapStatusToDisplay, statusToColor, interviewStatusToColor, generateInterviewStatusOptions, mapInterviewStatusToDisplay } from '../displayHelpers';
import { addOption, getOptions } from '../optionsHelpers';
import CallToActionBanner from './CallToActionBanner';

const formatDollarAmount = (num) => {
  if (!num) {
    return 'Not available'
  }
  // Create an Intl.NumberFormat object for U.S. English with specific options
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',  // Currency style formatting
      currency: 'USD',    // Use U.S. Dollar as the currency
      minimumFractionDigits: 1,  // Minimum number of decimal places to show
      maximumFractionDigits: 1   // Maximum number of decimal places to show
  });

  // Use the formatter to format the number and return it
  return formatter.format(num);
}

const isPaidMember = localStorage.getItem('isPro') === 'true';

const formatDate = (dateString) => {
  if (!dateString) {
    return 'Not available'
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth() returns month from 0-11; adding 1 to make it 1-12
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensures two digits
  const day = date.getDate().toString().padStart(2, '0'); // Ensures two digits

  return `${year}-${month}-${day}`;
};

const daysTillOfferExpiry = (offerDeadline) => {
  if (!offerDeadline) return 'Unavailable';  // Return null if no date is provided

    const deadlineDate = new Date(offerDeadline);
    if (isNaN(deadlineDate)) return 'Unavailable';  // Check if the date is valid

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);  // Reset time to start of the day for accuracy
    deadlineDate.setHours(0, 0, 0, 0);

    const timeDiff = deadlineDate - currentDate;  // Difference in milliseconds
    if (timeDiff < 0) return 0;  // If the deadline has passed, return 0

    const daysTillExpiry = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));  // Convert from ms to days
    return daysTillExpiry;
}

const daysSinceApplication = (applicationDate) => {
  if (!applicationDate) return 'Unavailable';  // Return 'Unavailable' if no date is provided

    const startDate = new Date(applicationDate);
    if (isNaN(startDate)) return 'Unavailable';  // Check if the date is valid

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);  // Reset time to start of the day for accuracy
    startDate.setHours(0, 0, 0, 0);

    const timeDiff = currentDate - startDate;  // Difference in milliseconds
    if (timeDiff < 0) return 'Unavailable';  // If the application date is in the future, return 'Unavailable'

    const daysSince = Math.floor(timeDiff / (1000 * 60 * 60 * 24));  // Convert from ms to days
    return daysSince;
}

const JOBS_PER_PAGE = 10

const JobApplicationsTable = () => {
  const [jobs, setJobs] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(JOBS_PER_PAGE); // Set how many jobs you want per page
  const [sortConfig, setSortConfig] = useState({ column: 'defaultColumn', direction: 'ascending' });
  const [interviewsVisible, setInterviewsVisible] = useState(true);
  const [offersVisible, setOffersVisible] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);

  const [newJob, setNewJob] = useState({
    position: '',
    company: '',
    company_type: '',
    yoe_required: '',
    status: '',
    industry: '',
    job_link: '',
    date_applied: '',
    referral: '',
    resume: '',
    location: '',
    days_since_application: '',
    notes: '',
  });
  const [editingJobIndex, setEditingJobIndex] = useState(null);
  const [editedJob, setEditedJob] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const showDeleteModal = (job) => {
    setJobToDelete(job);
    setIsModalOpen(true);
  };

  const deleteJob = async () => {
    if (jobToDelete) {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications/${jobToDelete._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (response.ok) {
        toast.success('Deleted job application successfully!');
        setIsModalOpen(false);  
        await fetchApplications()
      } else {
        toast.error('Failed to delete job application');
      }
    }
  };
  
  const fetchApplications = async (sortColumn = sortConfig.column, sortDirection = sortConfig.direction) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications?sortColumn=${sortColumn}&sortDirection=${sortDirection}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    // Update your jobs state with the sorted data
    const data = await response.json();
    setJobs(data);
  };

  const handleSort = (columnName) => {
    const direction = sortConfig.column === columnName && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    setSortConfig({ column: columnName, direction });
    // Fetch applications with updated sortConfig
    fetchApplications(columnName, direction);
  };

  const fetchCompanyOptions = async () => {
    const companyOptions = await getOptions('company')
    setCompanyOptions(companyOptions)
  }

  const handleCompanyAddition = async (e, { value }) => {
    await addOption('company', value)
    await fetchCompanyOptions()
  };

  useEffect(() => {
    const fetchData = async () => {
        await fetchApplications();
      };
    // TODO: industry options
    fetchData();
    fetchCompanyOptions();
  }, [])

  const handleNonOptionDropdownChange = (e, { name, value }) => {
    // Check if options exist which typically means it's a dropdown
      setNewJob({ ...newJob, [name]: value });
  };

  const handleEditedNonOptionDropdownChange = (e, { name, value }) => {
    // Check if options exist which typically means it's a dropdown
      setEditedJob({ ...editedJob, [name]: value });
  };

  const handleInputChange = (e, { name, value, options }) => {
    // Check if options exist which typically means it's a dropdown
    if (options) {
      const selectedOption = options.find(option => option.value === value);
      setNewJob({ ...newJob, [name]: selectedOption || value });
    } else {
      setNewJob({ ...newJob, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(newJob),
    });

    if (response.ok) {
      toast.success('Added new job application successfully!');
      await fetchApplications()
    } else {
      toast.error('Failed to add new job application');
    }
  };

  const handleEditClick = (index, job) => {
    setEditingJobIndex(index);
    setEditedJob(job);
  };

  const handleCancelClick = () => {
    setEditingJobIndex(null);
    setEditedJob({});
  };

  const handleEditInputChange = (e, { name, value, options }) => {
    if (options) {
      const selectedOption = options.find(option => option.value === value);
      setEditedJob({ ...editedJob, [name]: selectedOption || value });
    } else {
      setEditedJob({ ...editedJob, [name]: value });
    }
  };
  

  const handleSubmitEdit = async (index) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications/edit`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(editedJob),
    });

    if (response.ok) {
      toast.success('Edited job application successfully!');
      await fetchApplications()
    } else {
      toast.error('Failed to edit job application');
    }
    setEditingJobIndex(null);
    setEditedJob({});
  };

  // Calculate the jobs to display on the current page
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  // Change page
  const handlePaginationChange = (e, { activePage }) => setCurrentPage(activePage);

  return (
    <>
      {!isPaidMember ? <CallToActionBanner message={'You can track upto 10 jobs. You need a Pro subscription to go unlimited!'} buttonLink={'/account'} buttonText={'Go to Subscriptions Page'} /> : null}
      <div style={{ marginBottom: '10px' }}>
        <Checkbox
          label="Show Interviews"
          checked={interviewsVisible}
          onChange={(e, { checked }) => setInterviewsVisible(checked)}
        />
        <Checkbox
          label="Show Offers"
          checked={offersVisible}
          onChange={(e, { checked }) => setOffersVisible(checked)}
          style={{ marginLeft: '20px' }}
        />
      </div>

    <div className='tableScroll'>

      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="13">Applications</Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }} colSpan="6">Interviews</Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: offersVisible ? '' : 'none' }} colSpan="8">Offers</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" className='freeze' >Actions</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              Position
              <Icon 
                name={sortConfig.column === 'position' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('position')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
              <Popup
                content='The title for the job - titles matter for resume.'
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
              </Table.HeaderCell>
            <Table.HeaderCell>
              Company
              <Icon 
                name={sortConfig.column === 'company' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('company')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
              <Popup
                content='The title for the job - titles matter for resume.'
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              Company Type
              <Icon 
                name={sortConfig.column === 'position' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('position')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
              <Popup
                content='Select from a dropdown of values; the company type is important in determining what risk/reward level you want to work with for the next role. For non-public companies, go to crunchbase.com to find stage information.'
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Years of Experience Required</Table.HeaderCell>
            <Table.HeaderCell >Status
              <Icon 
                name={sortConfig.column === 'status' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('status')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
            </Table.HeaderCell>
            <Table.HeaderCell >
              Industry
              <Popup
                content='You should go into an industry you are interested in since you will become a subject matter expert in the space.'
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell >Job Link</Table.HeaderCell>
            <Table.HeaderCell >
              Date Applied
              <Icon 
                name={sortConfig.column === 'date_applied' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('date_applied')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
            </Table.HeaderCell>
            <Table.HeaderCell >
              Referral
              <Popup
                content="Add the name of your referral. Highly recommend finding someone working at the company on LinkedIn who would agree to give you a referral.
                Search for 'Position' 'Company' on Linked in based on the position and the company you are trying to find a referral for (e.g. Software Engineer Meta). A sample connection invite should read something like:
                'Hello Jim, I have been eyeing Dunder Mifflin for a while and I've come across a job that seems like a good fit for me. Could I ask you a few questions?'"
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell >
              Resume
              <Popup
                content="The Resume template to use. Tailor your resume for the jobs you really want, by highlighting skills, technologies, experiences, and coursework that are relevant to the role."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell >Location
              <Icon 
                name={sortConfig.column === 'location' ? (sortConfig.direction === 'ascending' ? 'caret up' : 'caret down') : 'sort'} 
                onClick={() => handleSort('location')}
                style={{ cursor: 'pointer' }}  // Makes the icon appear clickable
              />
            </Table.HeaderCell>
            <Table.HeaderCell >Days Since Application</Table.HeaderCell>
            <Table.HeaderCell >
              Notes
              <Popup
                content="Note about what is interests about the role and how your background is a unique fit for it. Use this information during interviews to explain why you are interested in the role, and highlight which of your experiences will be most relevant to the role."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell >
            {/* Interviews Column Headers  */}
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              HR/Hiring manager Screen
              <Popup
                content="HR Screen will be a short call with an HR person who will briefly ask about your background, and explain the requirements for the role to determine if you are a good fit."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              HR Screen Interview Notes
              <Popup
                content={
                  <div>
                    This to note include:
                    <ul>
                      <li>Steps in the interview and the timeline for completing the interview process</li>
                      <li>Salary expecations</li>
                      <li>Is the work in-person, hybrid, or remote. Is relocation offered?</li>
                      <li>Is the role going to accommodate your VISA status? Will there be an opportunity to sponsor an H1B or a greencard?</li>
                    </ul>
                  </div>
                }
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              First Round
            </Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              First Round Interview Notes
              <Popup
                content="Seek feedback, even if you were rejected by an interview (if the company policy allows sharing feedback."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>Final Round(s)</Table.HeaderCell>
            <Table.HeaderCell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              Final Rounds(s) Interview Notes
              <Popup
                content="Seek feedback, even if you were rejected by an interview (if the company policy allows sharing feedback."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            {/* Offers Column Headers  */}
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Total Compensation Offered (USD)
              <Popup
                content="The total compensation is the sum of base salary, annual stock awards, and annual bonuses offered. A sign on bonus and relocation assistance may be offered as well."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Total Compensation Expected (USD)
              <Popup
                content="Research the average total compensation expected for your years of experience at this company. levels.fyi is a great resource."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Cost of Living Co-efficient
              <Popup
                content="The cost of living co-efficient is a way to compare how expensive each location is compared to others. Use a resource like www.numbeo.com/cost-of-living to find the cost of living index. The baseline value should be 1.00. Higher cost of living gets a higher ratio."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Normalized Cost of Living
              <Popup
                content="Total compensation, adjusted for the cost of living"
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Work Life Balance Score
              <Popup
                content="Rate the work-life balance at the company for the role out of 5. Ratings are subjective, but do your research using websites such as teamblind.com and glassdoor.com."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Room to negotiate (USD)
              <Popup
                content="The difference between the expected compensation and the compensation you are offered. If the number is negative, you are being paid more than the expected compensation. (Always negotiate)"
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Offer deadline
              <Popup
                content="The date by which you will need to accept the offer by signing the written offer letter provided to you."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
            <Table.HeaderCell positive style={{ display: offersVisible ? '' : 'none' }}>
              Days till offer expiry
              <Popup
                content="Days remaining for you to sign the written offer letter issued to you before the offer expires."
                trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Input
                placeholder="Position"
                name="position"
                value={newJob.position}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
               <Dropdown
                  name="company"
                  label="Company"
                  placeholder="Company"
                  fluid selection search
                  options={companyOptions}
                  onAddItem={handleCompanyAddition}
                  value={newJob.company && newJob.company.value}
                  allowAdditions={true}
                  onChange={handleInputChange}
                />
            </Table.Cell>
            <Table.Cell>
              <Dropdown
                placeholder='Company Type'
                fluid
                selection
                name="company_type"
                onChange={handleNonOptionDropdownChange}
                options={generateCompanyTypeOptions()}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Years of Experience Required"
                name="yoe_required"
                value={newJob.yoe_required}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
            <Dropdown
                placeholder='Status'
                fluid
                selection
                name="status"
                onChange={handleNonOptionDropdownChange}
                options={generateStatusOptions()}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Industry"
                name="industry"
                value={newJob.industry}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Job Link"
                name="job_link"
                value={newJob.job_link}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Date Applied"
                type="date"
                name="date_applied"
                value={newJob.date_applied}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Referral"
                name="referral"
                value={newJob.referral}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Resume"
                name="resume"
                value={newJob.resume}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Location"
                name="location"
                value={newJob.location}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell>
              <div>(Calculation pending)</div>
            </Table.Cell>
            <Table.Cell>
              <Input
                placeholder="Notes"
                name="notes"
                value={newJob.notes}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Dropdown
                placeholder='HR Screen status'
                fluid
                selection
                name="hr_screen_status"
                onChange={handleNonOptionDropdownChange}
                options={generateInterviewStatusOptions()}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Input
                placeholder="HR Screen Notes"
                name="hr_screen_notes"
                value={newJob.hr_screen_notes}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Dropdown
                placeholder='First round status'
                fluid
                selection
                name="first_round_status"
                onChange={handleNonOptionDropdownChange}
                options={generateInterviewStatusOptions()}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Input
                placeholder="First Round Notes"
                name="first_round_notes"
                value={newJob.first_round_notes}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Dropdown
                placeholder='Final round status'
                fluid
                selection
                name="final_round_status"
                onChange={handleNonOptionDropdownChange}
                options={generateInterviewStatusOptions()}
              />
            </Table.Cell>
            <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>
              <Input
                placeholder="Final Round Notes"
                name="final_round_notes"
                value={newJob.final_round_notes}
                onChange={handleInputChange}
              />
            </Table.Cell>
            {/* Offer Evaluation Cells vvv */}
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <Input
                placeholder="Total comp expected..."
                name="total_comp_expected"
                value={newJob.total_comp_expected}
                onChange={handleInputChange}
                type="number"
              />
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <Input
                placeholder="Total comp offered..."
                name="total_comp_offered"
                value={newJob.total_comp_offered}
                onChange={handleInputChange}
                type="number"
              />
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <Input
                placeholder="Cost of living co-efficient"
                name="coe_coefficient"
                value={newJob.coe_coefficient}
                onChange={handleInputChange}
                type="number"
              />
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <p>(Calculation pending)</p>
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <Rating
                name="wlb_score"
                maxRating={5}
                clearable
                rating={newJob.wlb_score}
                onRate={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <p>(Calculation pending)</p>
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <Input
                placeholder="Offer deadline"
                type="date"
                name="offer_deadline"
                value={newJob.offer_deadline}
                onChange={handleInputChange}
              />
            </Table.Cell>
            <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
              <p>(Calculation pending)</p>
            </Table.Cell>
            {/* Offer Evaluation Cells ^^^ */}
            <Table.Cell className='freeze'>
              <Button onClick={handleSubmit} positive>Add Job</Button>
            </Table.Cell>
          </Table.Row>
          {currentJobs.map((job, index) => (
            <Table.Row key={index}>
            {editingJobIndex === index ? (
              // Render input fields for the editing row
              <>
                <Table.Cell>
                  <Input
                    placeholder="Position"
                    name="position"
                    value={editedJob.position}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    name="company"
                    label="Company"
                    placeholder="Company"
                    fluid selection search
                    options={companyOptions}
                    onAddItem={handleCompanyAddition}
                    value={editedJob.company && editedJob.company.value}
                    allowAdditions={true}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell >
                  <Dropdown
                    upward
                    placeholder='Company Type'
                    fluid
                    selection
                    name="company_type"
                    value={editedJob.company_type}
                    onChange={handleEditedNonOptionDropdownChange}
                    options={generateCompanyTypeOptions()}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Years of Experience Required"
                    name="yoe_required"
                    value={editedJob.yoe_required}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                      placeholder='Status'
                      upward
                      fluid
                      selection
                      value={editedJob.status}
                      name="status"
                      onChange={handleEditedNonOptionDropdownChange}
                      options={generateStatusOptions()}
                    />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Industry"
                    name="industry"
                    value={editedJob.industry}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Job Link"
                    name="job_link"
                    value={editedJob.job_link}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Date Applied"
                    type="date"
                    name="date_applied"
                    value={editedJob.date_applied}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Referral"
                    name="referral"
                    value={editedJob.referral}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Resume"
                    name="resume"
                    value={editedJob.resume}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Location"
                    name="location"
                    value={editedJob.location}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div>(Calculation pending)</div>
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Notes"
                    name="notes"
                    value={editedJob.notes}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                      placeholder='HR Screen Status'
                      upward
                      fluid
                      selection
                      value={editedJob.hr_screen_status}
                      name="hr_screen_status"
                      onChange={handleEditedNonOptionDropdownChange}
                      options={generateInterviewStatusOptions()}
                    />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="HR Screen Notes"
                    name="hr_screen_notes"
                    value={editedJob.hr_screen_notes}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                      placeholder='First Round Status'
                      upward
                      fluid
                      selection
                      value={editedJob.first_round_status}
                      name="first_round_status"
                      onChange={handleEditedNonOptionDropdownChange}
                      options={generateInterviewStatusOptions()}
                    />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="First Round Notes"
                    name="first_round_notes"
                    value={editedJob.first_round_notes}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                      placeholder='Final Round Status'
                      upward
                      fluid
                      selection
                      value={editedJob.final_round_status}
                      name="final_round_status"
                      onChange={handleEditedNonOptionDropdownChange}
                      options={generateInterviewStatusOptions()}
                    />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    placeholder="Final Round Notes"
                    name="final_round_notes"
                    value={editedJob.final_round_notes}
                    onChange={handleEditInputChange}
                  />
                </Table.Cell>
              {/* Offer Evaluation Cells vvv */}
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <Input
                  placeholder="Total Compensation Expected (USD)"
                  name="total_comp_expected"
                  value={editedJob.total_comp_expected}
                  onChange={handleEditInputChange}
                />
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <Input
                  placeholder="Total Compensation Offered (USD)"
                  name="total_comp_offered"
                  value={editedJob.total_comp_offered}
                  onChange={handleEditInputChange}
                />
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <Input
                  placeholder="Cost of living co-efficient"
                  name="coe_coefficient"
                  value={editedJob.coe_coefficient}
                  onChange={handleEditInputChange}
                />
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <p>(Calculation pending)</p>
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <Rating
                  name="wlb_score"
                  maxRating={5}
                  clearable
                  rating={editedJob.wlb_score}
                  onRate={handleEditInputChange}
                />
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <p>(Calculation pending)</p>
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <Input
                  placeholder="Offer deadline"
                  type="date"
                  name="offer_deadline"
                  value={editedJob.offer_deadline}
                  onChange={handleEditInputChange}
                />
              </Table.Cell>
              <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>
                <p>(Calculation pending)</p>
              </Table.Cell>
              {/* Offer Evaluation Cells ^^^ */}
                <Table.Cell className='freeze'>
                  <Button onClick={() => handleSubmitEdit(index)} positive>Submit</Button>
                  <Button onClick={handleCancelClick}>Cancel</Button>
                </Table.Cell>
              </>
            ) : (
              // Render text and possibly disabled edit button for other rows
              <>
                <Table.Cell>{job.position}</Table.Cell>
                <Table.Cell>{job.company.value}</Table.Cell>
                <Table.Cell>{job.company_type ? createLabel(companyTypeToColor(job.company_type), mapCompanyTypeToDisplay(job.company_type), 'road') : 'Unavailable'}</Table.Cell>
                <Table.Cell>{job.yoe_required}</Table.Cell>
                <Table.Cell>{job.status ? createLabel(statusToColor(job.status), mapStatusToDisplay(job.status), 'road') : 'Unavailable'}</Table.Cell>
                <Table.Cell>{job.industry}</Table.Cell>
                <Table.Cell>
                  <a href={job.job_link} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </Table.Cell>
                <Table.Cell>{formatDate(job.date_applied)}</Table.Cell>
                <Table.Cell>{job.referral}</Table.Cell>
                <Table.Cell>{job.resume}</Table.Cell>
                <Table.Cell>{job.location}</Table.Cell>
                <Table.Cell>{daysSinceApplication(job.date_applied)}</Table.Cell>
                <Table.Cell>{job.notes}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.hr_screen_status ? createLabel(interviewStatusToColor(job.hr_screen_status), mapInterviewStatusToDisplay(job.hr_screen_status), 'road') : 'Unavailable'}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.hr_screen_notes}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.first_round_status ? createLabel(interviewStatusToColor(job.first_round_status), mapInterviewStatusToDisplay(job.first_round_status), 'road') : 'Unavailable'}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.first_round_notes}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.final_round_status ? createLabel(interviewStatusToColor(job.final_round_status), mapInterviewStatusToDisplay(job.final_round_status), 'road') : 'Unavailable'}</Table.Cell>
                <Table.Cell warning style={{ display: interviewsVisible ? '' : 'none' }}>{job.final_round_notes}</Table.Cell>
                {/* Offer Cells vvv */}
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{formatDollarAmount(job.total_comp_expected)}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{formatDollarAmount(job.total_comp_offered)}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{job.coe_coefficient}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{formatDollarAmount(job.normalized_total_comp) || 'Unavailable'}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{
                    <Rating
                      maxRating={5}
                      rating={job.wlb_score}
                      disabled
                    />
                }</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{formatDollarAmount(job.room_to_negotiate)}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{formatDate(job.offer_deadline)}</Table.Cell>
                <Table.Cell positive style={{ display: offersVisible ? '' : 'none' }}>{daysTillOfferExpiry(job.offer_deadline)}</Table.Cell>

                {/* Offer Cells ^^^ */}
                <Table.Cell className='freeze'>
                  <ButtonGroup vertical>
                    <Button 
                      onClick={() => handleEditClick(index, job)}
                      disabled={editingJobIndex !== null && editingJobIndex !== index}
                    >
                      Edit
                      <Icon name="pencil" />
                    </Button>
                    <Button color="red" onClick={() => showDeleteModal(job)}>
                      <Icon name="trash" /> Delete
                    </Button>
                  </ButtonGroup>
                </Table.Cell>
              </>
            )}
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
      <Pagination
      activePage={currentPage}
      onPageChange={handlePaginationChange}
      totalPages={Math.ceil(jobs.length / jobsPerPage)}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      prevItem={{ content: 'Prev', icon: 'left chevron' }}
      nextItem={{ content: 'Next', icon: 'right chevron' }}
    />
    <Modal
      size="mini"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <Modal.Header>Delete Job Application</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete this job application?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setIsModalOpen(false)}>
          No
        </Button>
        <Button positive onClick={deleteJob}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
    </>
  );
};

export default JobApplicationsTable;
