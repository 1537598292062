import React, { useState } from 'react';
import { Button, Modal, Icon, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createLabel } from '../displayHelpers';

const MakeProjectProButton = ({ projectId, isPro, isCreator, cost }) => {
  const [open, setOpen] = useState(false);
  const [aiCreditCost, setAiCreditCost] = useState(0);

  const handleMakePro = async () => {
    // Call to backend API to upgrade project with specified AI credit cost
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}/upgrade`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ aiCreditCost })
    });

    if (response.ok) {
      // Handle successful upgrade
      toast.success("Upgraded project successfully!");
      setOpen(false);
    } else {
      // Handle error
      toast.error("Error upgrading project!");
      setOpen(false);
    }
  };

  const proBannerString = ['PRO Users only', cost && cost > 0 ? `(${cost} credits needed)` : null].filter(el => el !== null).join(' ')

  return (
    isPro ? createLabel('purple', proBannerString, 'shield') : isCreator ? <>
      <Button color="purple" size="tiny" style={{ marginTop: 2 }} onClick={() => setOpen(true)}>
        <Icon name="shield" />
        Upgrade Project to Pro
      </Button>
      <Modal
        size="mini"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>Upgrade Project</Modal.Header>
        <Modal.Content>
          <p>Once you upgrade this project to Pro, only pro users will be able to apply.</p>
          <p>Set the cost for this project in AI credits:</p>
          <Input
            type="number"
            value={aiCreditCost}
            onChange={(e) => setAiCreditCost(Number(e.target.value))}
            min="0"
            placeholder="Enter AI credit cost"
            fluid
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>Cancel</Button>
          <Button positive onClick={handleMakePro}>Set Cost & Upgrade</Button>
        </Modal.Actions>
      </Modal>
    </> : null
  );
};

export default MakeProjectProButton;
