import React, { useState } from 'react';
import { Button, Form, Input, Segment, Dropdown, Divider } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { jobRoleOptions, yoeOptions } from '../displayHelpers';

const ManualJobEntry = () => {
  const [jobLink, setJobLink] = useState('');
  const [liCookie, setLiCookie] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [yoe, setYoe] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/scrape`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    },
    body: JSON.stringify({ jobLink, jobRole, yoe }),
    });

    if (response.ok) {
      toast.success('Scraped job successfully!');
    } else {
      toast.error('Failed to scrape job!');
    }
    setLoading(false);
  };

  const updateCookie = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/updateCookie`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ liCookie }),
    });

    if (response.ok) {
      toast.success('Updated cookie!');
    } else {
      toast.error('Failed to update cookie!');
    }
  };


  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Field>
            <Input
              type="text"
              value={jobLink}
              onChange={(e) => setJobLink(e.target.value)}
              placeholder="Enter LinkedIn job link"
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              placeholder='Select Job Role'
              fluid
              selection
              options={jobRoleOptions()}
              value={jobRole}
              onChange={(_, { value }) => setJobRole(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              placeholder='Select YOE bucket'
              fluid
              selection
              options={yoeOptions()}
              value={yoe}
              onChange={(_, { value }) => setYoe(value)}
            />
          </Form.Field>
          <Button positive loading={loading} onClick={handleSubmit}>Scrape Job</Button>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Form.Field>
              <Input
                type="text"
                value={liCookie}
                onChange={(e) => setLiCookie(e.target.value)}
                placeholder="Enter LinkedIn Cookie"
              />
            </Form.Field>
          <Button positive onClick={updateCookie}>Update LinkedIn Cookie</Button>
        </Form.Group>
        </Form>
    </Segment>
  );
};

export default ManualJobEntry;
