import React, { useState } from 'react';
import { Container, Card, Icon, Label, Button, Modal, Accordion, List, Popup } from 'semantic-ui-react';
import TaskTimeline from './Timeline';
import { shorthandToDisplayTimeline, mapTechnologyToDisplay, createLabel, sanitizeString, formatParticipantsAsList, getCountryIcon, getProjectTitle } from '../displayHelpers';
import LongListLabel from './LongListLabel';
import DeleteProjectButton from './DeleteProjectButton';
import MakeProjectProButton from './MakeProjectProButton';
import ReadOnlyTasksList from './TimelineTasksListReadOnly';
import CopyToClipboard from './CopyToClipboard';
import ApplyToProjectModal from './ApplyToProjectModal';
import { toast } from 'react-toastify';
import CourseBuilder from './CourseBuilder';
import CourseInterface from './CourseInterface';

const DiscordInviteButton = ({ inviteLink }) => {
  return (
      <Container textAlign='center' style={{ marginTop: '20px' }}>
          <Button
              color='purple'
              href={inviteLink}
              target='_blank'
              rel='noopener noreferrer'
          >
              Join Discord Chat
          </Button>
      </Container>
  );
};

const ProjectListItem = ({
  project,
  isLoggedIn,
  isCreator,
  toggleTimelineModal,
  toggleApplicationModal,
  timelineModalOpen,
  applicationModalOpen,
handleInputChange,
  handleSubmitApplication,
  application,
  fetchProjects
}) => {

    const isPaidMember = localStorage.getItem('isPro') === 'true';

    const isActive = (index) => activeIndex.includes(index);
    const [activeIndex, setActiveIndex] = useState([]);
    const handleAccordionClick = (index) => {
        const newIndex = activeIndex.includes(index)
            ? activeIndex.filter(i => i !== index)
            : [...activeIndex, index];
        setActiveIndex(newIndex);
    };
    const [projectTimeline, setProjectTimeline] = useState(null);
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [likesCount, setLikesCount] = useState(project.likes ? project.likes.length : 0);
    const [isLiked, setIsLiked] = useState(project.likes && project.likes.includes(localStorage.getItem('userId')));

    const toggleLike = async (projectId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/like/${projectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            console.error(`Error liking project: ${errorText}`);
            toast.error(`Error liking project: ${errorText}`);
            return;
        };
    
        const data = await response.json();
        setLikesCount(data.likesCount);
        setIsLiked(data.liked);
    };
      
      const fetchProjectTimeline = async () => {
          setLoadingTimeline(true);
          try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline/${project.project_timeline}`, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                      'Content-Type': 'application/json',
                  },
              });
              if (!response.ok) throw new Error('Failed to fetch project timeline');
              const timelineData = await response.json();
              setProjectTimeline(timelineData);
          } catch (error) {
              console.error('Error fetching project timeline:', error);
          }
          setLoadingTimeline(false);
      };

    const isParticipant = (project) => {
      return project.participants.some(p => p.user._id === localStorage.getItem('userId'))
    }

    const pickTimelineDisplay = (project, toggleTimelineModal, isCreator) => {
      if (project.projectType === 'teachable_project') {
        if (isCreator) {
          return <CourseBuilder projectId={project._id} toggleModal={toggleTimelineModal} fetchProjects={()=>{}}/>
        }
        return <CourseInterface projectId={project._id} />
      }
      return <TaskTimeline projectId={project._id} toggleModal={toggleTimelineModal} fetchProjects={()=>{}}/>
    }

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {getProjectTitle(project)}
          {['study_group'].includes(project.projectType) ? 
              <Label>
                <Icon name='linkify' />
                <a href={project.course_link} target='_blank' rel='noopener noreferrer'>{project.course_title}</a>
            </Label> : null    
          }
           <br/>
          <Popup
            content={`${likesCount} user(s) have liked this project.`}
            trigger={
                <Button icon onClick={() => toggleLike(project._id)} style={{ backgroundColor: 'transparent', padding: 0, border: 'none' }}>
                    <Icon name={isLiked ? "heart" : "heart outline"} color={isLiked ? "red" : undefined}/>
                    {likesCount}
                </Button>
            }
          />
          <CopyToClipboard copyLink={`${process.env.REACT_APP_HOST}/projects/${project._id}`} copyText={'Copy project link'} />
        </Card.Header>
        <Card.Meta>
          {isCreator ?  createLabel('yellow', 'Creator', 'trophy', true) : null}
          {project.projectType ?  createLabel('teal', sanitizeString(project.intent), 'chess', true) : null}
        </Card.Meta>
        {project.country ? <Card.Meta>
          <span>Project Location: {getCountryIcon(project.country)}</span>
        </Card.Meta> : null}
        <Card.Description>
            {project.participants &&  project.participants.length ? <LongListLabel labels={formatParticipantsAsList(project.participants)} color="pink"/> : null}
          {project.allow_off_region_participation && (
            <Label color="green">Off-Region Participation Allowed</Label>
          )}
          {
            isCreator ? <DeleteProjectButton fetchProjects={fetchProjects} projectId={project._id}/> : null
          }
          <MakeProjectProButton isCreator={isCreator} isPro={project.pro} projectId={project._id} cost={project.aiCreditCost}/>

            <p style={{ margin: 5 }}>{project.background}</p>
        </Card.Description>
        {((isCreator || (project.pro && isPaidMember))) && (project.features?.length || project.project_timeline) ?  <Accordion fluid styled>
            {project.features?.length ? <Accordion.Title
                active={isActive(0)}
                index={0}
                onClick={() => handleAccordionClick(0)}
            >
                <Icon name='dropdown' />
                Features
            </Accordion.Title> : null}
            {project.features?.length ? <Accordion.Content active={isActive(0)}>
                <List>
                    {project.features?.map((feature, featureIndex) => (
                        <List.Item icon='gift' key={featureIndex} content={feature} />
                    ))}
                </List>
            </Accordion.Content> : null}
            {project.project_timeline && (
            <React.Fragment>
                <Accordion.Title
                    active={isActive(1)}
                    index={1}
                    onClick={() => {
                        handleAccordionClick(1);
                        if (!projectTimeline) fetchProjectTimeline();
                    }}
                >
                    <Icon name='dropdown' />
                    Project Timeline
                </Accordion.Title>
                <Accordion.Content active={isActive(1)}>
                    {loadingTimeline ? (
                        <div>Loading timeline...</div>
                    ) : projectTimeline ? (
                        <ReadOnlyTasksList tasks={projectTimeline.tasks} showDetails={(project.pro ? (isPaidMember || isCreator) : true)}/>
                    ) : (
                        <div>No timeline data available.</div>
                    )}
                </Accordion.Content>
            </React.Fragment>
        )}
        </Accordion> : null}
      </Card.Content>
      <Card.Content extra>
        {createLabel('teal', `${sanitizeString(project.status)}`, 'angle double right')}
        {project.kickoff_date ? createLabel('olive', `Kickoff Date: ${project.kickoff_date ? (new Date(project.kickoff_date).toLocaleDateString()) : 'None'}`, 'calendar outline') : null}
        {project.timeline ? createLabel('blue', shorthandToDisplayTimeline(project.timeline), 'time') : null}
        <LongListLabel labels={project && project.technologies.map(mapTechnologyToDisplay)} color={"blue"} />
        {
        (isLoggedIn && (isCreator || isParticipant(project)) && project.discord_invite_link) ? <DiscordInviteButton 
          inviteLink={project.discord_invite_link}
        /> : null
        }
      </Card.Content>
        {isLoggedIn && (isCreator || isParticipant(project)) && (
          <>
            <Button secondary onClick={() => toggleTimelineModal(project._id)}>
              Manage Project
            </Button>
            <Modal open={timelineModalOpen} onClose={() => toggleTimelineModal(project._id)}>
              <Modal.Header>Manage Project</Modal.Header>
              <Modal.Content>
                {pickTimelineDisplay(project, toggleTimelineModal, isCreator)}
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => toggleTimelineModal(project._id)}>Done</Button>
              </Modal.Actions>
            </Modal>
          </>
        )}

        {(!isCreator && !isParticipant(project)) && (
          <ApplyToProjectModal
            project={project}
            toggleApplicationModal={toggleApplicationModal}
            applicationModalOpen={applicationModalOpen}
            application={application}
            handleInputChange={handleInputChange}
            handleSubmitApplication={handleSubmitApplication}
        />
        )}
    </Card>
  );
};

export default ProjectListItem;
