import React, { useState } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const DeleteProjectButton = ({ projectId, fetchProjects }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    // Call to backend API to delete project
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    });
    if (response.ok) {
      // Handle successful deletion, e.g., redirect or update UI
      toast.success("Deleted project successfully!")
      console.log('Project deleted successfully');
      setOpen(false);
      fetchProjects()
      return
    }
    toast.error("Error deleting project!")
    setOpen(false);
  };

  return (
    <>
      <Button color="red" size="tiny" style={{marginTop: 2}} onClick={() => setOpen(true)}>
        <Icon name="trash" />
        Delete Project
      </Button>
      <Modal
        size="mini"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>Delete Project</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this project?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>No</Button>
          <Button positive onClick={handleDelete}>Yes</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DeleteProjectButton;
