export const checkTokenValidity = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;
  
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/validateToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
  
      if (!response.ok) throw new Error('Token validation failed');
  
      const data = await response.json();
      return {
        user: data.user,
        isValid: data.isValid
      }; // Assuming the response contains a boolean indicating token validity
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };