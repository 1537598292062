import React, { useState, useEffect } from 'react';
import { Form, Header, Icon, Accordion, Divider, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ContributionsEdit from './ContributionsEdit';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addOption, getOptions } from '../optionsHelpers';

const WorkExperienceEdit = ({resumeId, activeIndex}) => {
    const [workExperiences, setWorkExperiences] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([])
    const [newExperience, setNewExperience] = useState({
        company: '',
        role: '',
        startDate: '',
        endDate: '',
        description: '',
    });

    const fetchCompanyOptions = async () => {
        const companyOptions = await getOptions('company')
        setCompanyOptions(companyOptions)
    };
    
    const handleCompanyAddition = async (value) => {
      setCompanyOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
      await addOption('company', value);  // Assume this function updates the backend
      await fetchCompanyOptions();
    };

    const handleCompanyChange = (index, company) => {
        let updatedExperiences = [...workExperiences]
        const selectedOption = companyOptions.find(option => option.value === company);
        if (selectedOption) {
          updatedExperiences[index]['company_id'] = selectedOption.key;
          updatedExperiences[index]['company'] = company;
          setWorkExperiences(updatedExperiences);
        }
    };

    const handleNewExpCompanyChange = (company) => {
      const selectedOption = companyOptions.find(option => option.value === company);
      if (selectedOption) {
        setNewExperience(prevState => ({ ...prevState, ['company']: company, ['company_id']: selectedOption.key }));
      }
  };

    const fetchWorkExperiences = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/workExperiences/${resumeId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
      
          const data = await response.json();
          setWorkExperiences(data)
      }
    
      const deleteWorkExperience = async (workId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/workExperience/${workId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
      
          if (response.ok) {
            toast.success('Deleted work experience successfully!');
            await fetchWorkExperiences()
          } else {
            toast.error('Failed to delete work experience');
          }
      }
    
      const handleAddExperience = async (e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/workExperience/${resumeId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ workExperience: newExperience }),
          });
      
          if (response.ok) {
            toast.success('Added work experience successfully!');
            await fetchWorkExperiences()
            setNewExperience({
              company: '',
              role: '',
              startDate: '',
              endDate: '',
              description: '',
            })
          } else {
            toast.error('Failed to add work experience');
          }
      }

      const handleNewWorkExperienceChange = (field, value) => {
        setNewExperience(prevState => ({ ...prevState, [field]: value }));
      };

      const handleExistingWorkExperienceChange = (index, field, value) => {
        let updatedExperiences = [...workExperiences]
        updatedExperiences[index][field] = value;
        setWorkExperiences(updatedExperiences);
      };

      const handleUpdateExperience = async (index, e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/workExperience/${workExperiences[index]._id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ workExperience: workExperiences[index] }),
          });
      
          if (response.ok) {
            toast.success('Updated work experience successfully!');
            await fetchWorkExperiences()
          } else {
            toast.error('Failed to update work experience');
          }
      }
    
      useEffect(() => {
        const fetchData = async () => {
            await fetchWorkExperiences();
          };
          fetchData();
          fetchCompanyOptions()
      }, [])
    

    const renderExistingWorkExperiences = (experience, index) => (
        <Form>
            <div className='container'>
                <Header as="h4">Edit Work Experience #{index + 1}</Header>
                
                <Icon name="trash" size="large" className="icon-hover" onClick={() => deleteWorkExperience(experience._id)}/>
            </div>
            <Form.Input
                fluid label="Job Title" placeholder="Job Title"
                value={experience.jobTitle}
                onChange={(e) => handleExistingWorkExperienceChange(index, 'jobTitle', e.target.value)}
            />
            <Form.Dropdown
              name="company"
              label="Company"
              placeholder="Company"
              fluid selection search
              options={companyOptions}
              onAddItem={(e, {value}) => handleCompanyAddition(value)}
              value={experience.company}
              allowAdditions={true}
              onChange={(e, { value }) => handleCompanyChange(index, value)}
            />
            <Form.Input
                fluid label="Location" placeholder="Location"
                value={experience.location}
                onChange={(e) => handleExistingWorkExperienceChange(index, 'location', e.target.value)}
            />
            <Form.Group widths="equal">
                <div style={{marginLeft: 10}}>
                  <label htmlFor='startDatePicker'><strong>Start Date</strong></label>
                  <div>
                    <ReactDatePicker
                      selected={experience.startDate}
                      id="startDatePicker"
                      onChange={(date) => handleExistingWorkExperienceChange(index, 'startDate', date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </div>
                </div>

                {/* Conditionally render the End Date input field */}
                {!(experience.current || !experience.endDate) && (
                <div style={{marginLeft: 10}}>
                  <label htmlFor='endDatePicker'><strong>End Date</strong></label>
                  <div>
                    <ReactDatePicker
                      id="endDatePicker"
                      selected={experience.endDate}
                      onChange={(date) => handleExistingWorkExperienceChange(index, 'endDate', date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </div>
                </div>
                )}
                <Form.Checkbox
                label="Current"
                checked={experience.current || !experience.endDate}
                onChange={() => {
                    // Toggle the 'current' state and clear 'endDate' if 'current' is checked
                    const updatedExperiences = [...workExperiences];
                    workExperiences[index].current = !updatedExperiences[index].current;
                    if (!workExperiences[index].endDate) {
                      workExperiences[index].endDate = new Date()
                    } else {
                      workExperiences[index].endDate = null
                    }
                    if (updatedExperiences[index].current) {
                        updatedExperiences[index].endDate = null; // Set endDate to null if current job
                    }
                    setWorkExperiences(updatedExperiences);
                }}
                />
            </Form.Group>
            <ContributionsEdit 
                projectId={experience._id}
                modelType={'WorkExperience'}
            />
            <Form.Button positive onClick={(e) => handleUpdateExperience(index, e)}>Update Work Experience #{index+1}</Form.Button>
            <Divider/>
        </Form>
    );

    const renderNewWorkExperienceForm = () => {
        return (
          <Segment>
            <Form>
                <div style={{marginBottom: 5}} className='container'>
                    <Header as="h4">Add New Work Experience Project</Header>
                </div>
                <Form.Input
                        fluid label="Job Title" placeholder="Job Title"
                        value={newExperience.jobTitle}
                        onChange={(e) => handleNewWorkExperienceChange('jobTitle', e.target.value)}
                    />
                    <Form.Dropdown
                      name="company"
                      label="Company"
                      placeholder="Company"
                      fluid selection search
                      options={companyOptions}
                      onAddItem={(e, {value}) => handleCompanyAddition(value)}
                      value={newExperience.company}
                      allowAdditions={true}
                      onChange={(e, { value }) => handleNewExpCompanyChange(value)}
                    />
                    <Form.Input
                        fluid label="Location" placeholder="Location"
                        value={newExperience.location}
                        onChange={(e) => handleNewWorkExperienceChange('location', e.target.value)}
                    />
                        <Form.Group widths="equal">
                          <div style={{marginLeft: 10}}>
                            <label htmlFor='startDatePicker'><strong>Start Date</strong></label>
                            <div>
                              <ReactDatePicker
                                selected={newExperience.startDate}
                                id="startDatePicker"
                                onChange={(date) => handleNewWorkExperienceChange('startDate', date)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                              />
                            </div>
                          </div>

                          {/* Conditionally render the End Date input field */}
                          {!(newExperience.current || !newExperience.endDate) && (
                          <div style={{marginLeft: 10}}>
                            <label htmlFor='endDatePicker'><strong>End Date</strong></label>
                            <div>
                              <ReactDatePicker
                                id="endDatePicker"
                                selected={newExperience.endDate}
                                onChange={(date) => handleNewWorkExperienceChange('endDate', date)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                              />
                            </div>
                          </div>
                          )}
                          <Form.Checkbox
                            label="Current"
                            checked={newExperience.current || !newExperience.endDate}
                            onChange={() => {
                              const updatedExperience = {...newExperience};
                              updatedExperience.current = !updatedExperience.current;
                              if (!updatedExperience.endDate) {
                                updatedExperience.endDate = new Date()
                              } else {
                                updatedExperience.endDate = null
                              }
                              if (updatedExperience.current) {
                              updatedExperience.endDate = null; // Set endDate to null if current job
                              }
                              setNewExperience(updatedExperience);
                            }}
                          />
                      </Form.Group>
                    <Form.Button positive disabled={
                      !newExperience.startDate || !newExperience.company || !newExperience.jobTitle
                    } onClick={handleAddExperience}>Add</Form.Button>
            </Form>
            </Segment>
        )
    }

    return (
    <Accordion.Content active={activeIndex === 3}>
        <Form>
            {renderNewWorkExperienceForm()}
            {workExperiences.map((experience, index) => renderExistingWorkExperiences(experience, index))}
        </Form>
    </Accordion.Content>
    );
};

export default WorkExperienceEdit;
