import React, { useState, useEffect } from 'react';
import { Card, Button, Icon, Segment, Message } from 'semantic-ui-react';
import { mapReferralOptionToDisplay, createLabel } from '../displayHelpers';
import { useParams, useNavigate } from 'react-router-dom';
import Resume from './Resume';

const UserPage = () => {
    const { user_id } = useParams();
    const [user, setUser] = useState(null); // Set initial state to `null`
    const [resume, setResume] = useState(null); // Set initial state to `null`

    const navigate = useNavigate();

    const fetchResume = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/api/resume/get_one/${user_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                }
                ); // Adjust endpoint as necessary
            if (!response.ok) throw new Error('Failed to fetch resume');
            const data = await response.json();
            setResume(data);
        } catch (error) {
            console.error('Error fetching resume:', error);
        }
    };

    // Fetch project details from the backend API
    const fetchUserDetails = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/get_one/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                setUser(responseData);
            } else {
                console.error('Failed to fetch user');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        fetchUserDetails(user_id);
        fetchResume();
    }, [user_id]);

    return (
        user ? (
            <Segment>
                <Card key={user._id} fluid>
                    <Card.Content>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Card.Header as='h4'>{user.fullname}</Card.Header>
                            </div>
                            <Button size="small" icon labelPosition='left' onClick={() => navigate(-1)} style={{ margin: '5px' }}>
                            <Icon name='left arrow' />
                            Back
                            </Button>
                        </div>
                        <Card.Meta>{user.current_company}</Card.Meta>
                        {user.institution_labels && user.institution_labels.length ? <Card.Meta>{user.institution_labels.map(
                            ins => createLabel('blue', ins, 'graduation cap')
                        )}</Card.Meta>: null}
                        <Card.Description>{mapReferralOptionToDisplay(user.referral_stance)}</Card.Description>
                    </Card.Content>
                </Card>
                {
                user.reveal_resume ?
                    (resume ? <Resume resume={resume} /> : null)
                    :
                    <Message info> {user.fullname} has opted to keep their resume private. </Message>
                }
            </Segment>
        ) : null
    );
};

export default UserPage;
