import React, { useState } from 'react';
import { Card, Button, Label, Icon, Dropdown } from 'semantic-ui-react';
import LongListLabel from './LongListLabel';
import { allowFeature  } from '../feature_flags_service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
};

const capitalizeFirstLetter = (string) => {
    if (!string) return string; // return original string if it's empty or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

const getRatingLabelColor = (rating) => {
    switch (rating) {
        case 'poor':
            return 'red';
        case 'average':
            return 'orange';
        case 'good':
            return 'yellow';
        case 'excellent':
            return 'green';
        default:
            return 'grey';
    }
}

const getRatingIconName = (rating) => {
    switch (rating) {
        case 'poor':
            return 'frown outline';
        case 'average':
            return 'frown outline';
        case 'good':
            return 'smile outline';
        case 'excellent':
            return 'star outline';
        default:
            return 'question circle outline';
    }
}

const JobCard = ({ job, isLoggedIn, isAdmin, flags, toggleModal, openJobLink, createTailoredResume, addToMyJobs, handleExperienceChange, deleteJob, analytics, logEvent, yoeOptions, index, fetchJobs }) => {

    const [loading, setLoading] = useState(false)

    const generateProjectForJob = async (jobId) => {
        setLoading(true)
        try {
            const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/projects/createJobForProject/${jobId}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            }
            );
            if (response.ok) {
                const json = await response.json()
                setLoading(false)
                toast.success(json.message || 'Created project for job!');
                fetchJobs()
            } else {
                setLoading(false)
                toast.error('Error creating project for job!');
                console.error('Error creating project for job');
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message || 'Error creating project for job!');
            console.error('Error creating project for job:', error);
        }
    };

    const isPaidMember = localStorage.getItem('isPro') === 'true';

    return (
        <Card key={index}>
        <Card.Content>
            <Card.Header>{job.title}</Card.Header>
            <Card.Meta>{job.company}</Card.Meta>
            <Card.Description>
                <strong>Location:</strong> {job.place}
            </Card.Description>
            <Label color='teal' ribbon='right'>
                Date Posted: {job.date ? formatDate(job.date) : 'Not available'}
            </Label>
            {job.hand_picked && <Label color='orange' ribbon='right'>
                Hand Picked
            </Label>}
        </Card.Content>
            {
            [job.career_growth_categorization, job.compensation_and_benefits_categorization, job.company_culture_categorization, job.management_categorization, job.work_life_balance_categorization].some(element => element !== null && element !== "") ?
            <Card.Content extra>
                {job.career_growth_categorization ? 
                <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.career_growth_categorization)}>
                    <Icon name={getRatingIconName(job.career_growth_categorization)} /> Career Growth: {capitalizeFirstLetter(job.career_growth_categorization)}
                </Label>
                : null}
                {job.compensation_and_benefits_categorization ? 
                <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.compensation_and_benefits_categorization)}>
                    <Icon name={getRatingIconName(job.compensation_and_benefits_categorization)} /> Pay/Benefits: {capitalizeFirstLetter(job.compensation_and_benefits_categorization)}
                </Label> 
                : null}
                {job.company_culture_categorization ? 
                <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.company_culture_categorization)}>
                    <Icon name={getRatingIconName(job.company_culture_categorization)} /> Culture: {capitalizeFirstLetter(job.company_culture_categorization)}
                </Label>  
                : null}
                {job.management_categorization ? 
                <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.management_categorization)}>
                    <Icon name={getRatingIconName(job.management_categorization)} /> Management: {capitalizeFirstLetter(job.management_categorization)}
                </Label>   
                : null}
                {job.work_life_balance_categorization ? 
                <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.work_life_balance_categorization)}>
                    <Icon name={getRatingIconName(job.work_life_balance_categorization)} /> Work Life Balance: {capitalizeFirstLetter(job.work_life_balance_categorization)}
                </Label> 
                : null}
            </Card.Content>
            : null
            }
            {
                job.skills ? 
                <Card.Content extra>
                    <LongListLabel labels={job.skills} color={"blue"}/>
                </Card.Content> : null
            }
            <Card.Content extra>
            <p>
                Job Description
            </p> 
            <Card.Description>
                {job.description.substring(0, 100)}...
                <Button size='tiny' style={{margin: 2}} icon labelPosition='right' onClick={() => toggleModal(job.description)}>
                    See More
                    <Icon name='right arrow' />
                </Button>
            </Card.Description>
            <Card.Content extra>
                <Button.Group vertical compact >
                    <Button primary onClick={() => openJobLink(job.link)}>Open Job</Button>
                    {isLoggedIn && allowFeature(flags, 'tailored_resumes') && <Button primary onClick={() => createTailoredResume(job._id)}>Create Tailored Resume</Button>}
                    {isLoggedIn && !isAdmin() && <Button primary onClick={() => {
                        if (analytics) {
                            logEvent(analytics, 'add_to_my_jobs', {
                                content_type: 'job',
                                content_id: job._id,
                                items: [{ name: 'added_job', job_id: job._id  }]
                            });
                        } else {
                            console.log('Analytics is not initialized');
                        }
                        addToMyJobs(job._id)
                    }}>Add To My Jobs</Button>}
                    {
                    isLoggedIn && isPaidMember && 
                    job.associatedProject ? 
                    <Button as={Link} to={`/projects/${job.associatedProject}`}>
                        See Preparatory Project
                        <Icon name="magic"/>
                    </Button>
                    :
                    <Button primary loading={loading} disabled={!isPaidMember} onClick={async () => {
                        if (analytics) {
                            logEvent(analytics, 'generate_project_for_job', {
                                content_type: 'job',
                                content_id: job._id,
                                items: [{ name: 'generated_project_for_job', job_id: job._id  }]
                            });
                        } else {
                            console.log('Analytics is not initialized');
                        }
                        await generateProjectForJob(job._id)
                    }}>Generate Project for Job</Button>
                    }
                    {isLoggedIn && isAdmin() && 
                        <Dropdown
                            style={{
                                margin: '2px'
                            }}
                            placeholder='Select Years of Experience'
                            fluid
                            selection
                            options={yoeOptions()}
                            value={job.years_of_experience}
                            onChange={(e, {value}) => handleExperienceChange(e, job._id, value)}
                        />
                    }
                    {isLoggedIn && isAdmin() && <Button size='mini' color='red' onClick={() => deleteJob(job._id)}>Delete</Button>}
                </Button.Group>
            </Card.Content>
        </Card.Content>
    </Card>
    );
};

export default JobCard;
