import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import JobsList from './JobsList';
import MassJobsUpload from './MassJobsUpload';
import CompanyAdmin from './CompanyAdmin';
import ManualJobEntry from './ManualJobEntry';
import MenuBar from '../MenuBar';

const JobsViewAdmin = ({isLoggedIn, currentRole, flags}) => {
  const [activeTab, setActiveTab] = useState('automatic');

  const handleTabClick = (e, { name }) => setActiveTab(name);

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'automatic':
        return <JobsList isLoggedIn={isLoggedIn} currentRole={currentRole} flags={flags}/>;
      case 'manual':
        return <ManualJobEntry />;
      case 'mass':
        return <MassJobsUpload />;
      case 'companies':
        return <CompanyAdmin />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLoggedIn && <MenuBar isLoggedIn={isLoggedIn} isAdmin={true}/>}
      <Segment>
      <Menu attached='top' tabular>
          <Menu.Item
            name='automatic'
            active={activeTab === 'automatic'}
            onClick={handleTabClick}
          >
            Automatically Curated Jobs
          </Menu.Item>

          <Menu.Item
            name='manual'
            active={activeTab === 'manual'}
            onClick={handleTabClick}
          >
            Manually Curate Jobs
          </Menu.Item>

          <Menu.Item
            name='mass'
            active={activeTab === 'mass'}
            onClick={handleTabClick}
          >
            Mass Upload Jobs
          </Menu.Item>
          <Menu.Item
            name='companies'
            active={activeTab === 'companies'}
            onClick={handleTabClick}
          >
            Companies
          </Menu.Item>
        </Menu>

        <Segment attached='bottom'>
          {renderActiveTabContent()}
        </Segment>
      </Segment>
    </>
  );
};

export default JobsViewAdmin;
