import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import JobsList from './JobsList';
import TailoredResumes from './TailoredResumes';
import MenuBar from '../MenuBar';
import JobApplicationsTable from './JobApplicationsTable';
import { allowFeature } from '../feature_flags_service';
import ApplicationInsights from './ApplicationInsights';
import CompanyTracker from './CompanyTracker';

const JobsView = ({isLoggedIn, currentRole, flags}) => {
  const [activeTab, setActiveTab] = useState('jobs_board'); 

  const handleTabClick = (e, { name }) => setActiveTab(name);

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'jobs_board':
        return <JobsList isLoggedIn={isLoggedIn} currentRole={currentRole} flags={flags}/>;
      case 'matches':
        return <TailoredResumes />;
      case 'applications':
        return <JobApplicationsTable />;
      case 'application_insights':
        return <ApplicationInsights />;
      case 'company_tracker':
        return <CompanyTracker isLoggedIn={isLoggedIn}/>;
      default:
        return null;
    }
  };

  return (
    <>
    {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn}/>}
        <Segment style={{ overflow: 'auto', maxHeight: 'calc(100vh - 120px)' }}>
        <Menu attached='top' tabular stackable>
            <Menu.Item
            name='jobs_board'
            active={activeTab === 'jobs_board'}
            onClick={handleTabClick}
            >
            Job Recommendations
            </Menu.Item>

            {
            allowFeature(flags, 'tailored_resumes') && <Menu.Item
            name='matches'
            active={activeTab === 'matches'}
            onClick={handleTabClick}
            >
            Your Tailored Resumes
            </Menu.Item>
            }

            <Menu.Item
            name='applications'
            active={activeTab === 'applications'}
            onClick={handleTabClick}
            >
            Your Job Applications
            </Menu.Item>
            <Menu.Item
            name='application_insights'
            active={activeTab === 'application_insights'}
            onClick={handleTabClick}
            >
            Application Insights
            </Menu.Item>
            <Menu.Item
            name='company_tracker'
            active={activeTab === 'company_tracker'}
            onClick={handleTabClick}
            >
            Company Insights
            </Menu.Item>
        </Menu>

        <Segment attached='bottom'>
            {renderActiveTabContent()}
        </Segment>
        </Segment>
    </>
  );
};

export default JobsView;
