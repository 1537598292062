import React from 'react';
import { Label, Popup } from 'semantic-ui-react';

const LongListLabel = ({ labels, color }) => {
  const maxVisible = 5;
  const visibleLabels = labels.slice(0, maxVisible);
  const remainingLabels = labels.slice(maxVisible);

  return (
    <div>
      {visibleLabels && visibleLabels.map((label, index) => (
        <Label size="tiny" style={{margin: 2}} basic key={index} color={color}>
          {label}
        </Label>
      ))}

      {remainingLabels.length > 0 && (
        <Popup
          trigger={
            <Label size="tiny" style={{margin: 2}} basic as='a' color={color}>
              +{remainingLabels.length} more...
            </Label>
          }
          content={remainingLabels.join(', ')}
          position='top center'
        />
      )}
    </div>
  );
};

export default LongListLabel;