import React, { useState, useEffect } from 'react';
import { Button, Card, Grid, Icon, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { allowFeature } from '../feature_flags_service';

function timeAgo(date) {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}

const mapTypeToDisplay = (type) => {
    switch (type) {
        case 'UserCreated':
            return 'New user joined!'
        case 'JobApplicationStatusChanged':
            return 'Someone\'s making progress :)'
        case 'NewJobsUpload':
          return 'Fresh Jobs added!'
        case 'ResumeAdded':
          return 'New resume added!'
        case 'ProjectAdded':
          return 'New project added!'
        default:
            return 'Unknown event :('
    }
}

const mapTypeToIcon = (type) => {
    switch (type) {
        case 'UserCreated':
            return 'user circle'
        case 'JobApplicationStatusChanged':
            return 'briefcase'
        case 'NewJobsUpload':
            return 'rocket'
        case 'ResumeAdded':
          return 'briefcase'
        case 'ProjectAdded':
          return 'paint brush'
        default:
            return 'qq'
    }
}

const EventCard = ({ event }) => {
  const navigate = useNavigate();

  const navigateToLink = () => {
    if (event.additionalInfo && event.additionalInfo.nav_link) {
      navigate(event.additionalInfo.nav_link);
    }
  };

  return (
    <Card>
      <Card.Content>
        <Card.Header>
            <Icon name={mapTypeToIcon(event.eventType)} />
            {mapTypeToDisplay(event.eventType)} 
        </Card.Header>
        <Card.Meta>{timeAgo(event.createdAt)}</Card.Meta>
        <Card.Description>{event.eventDescription}</Card.Description>
        {event.additionalInfo && event.additionalInfo.nav_link && (
          <Button onClick={navigateToLink} primary style={{ marginTop: '10px' }}>
            Check it out!
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};

const getFilteredEvents = (events, flags) => {
  let typesToSkip = []
  if (!allowFeature(flags, 'projects')) {
    typesToSkip.push('ProjectAdded')
  }
  if (!allowFeature(flags, 'resumes')) {
    typesToSkip.push('ResumeAdded')
  }
  return events.filter(ev => !typesToSkip.includes(ev.eventType))
}

const EventsFeed = () => {
  const fetchFlags = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
    {
      headers: {
          'Content-Type': 'application/json',
        },
    }
    );
    const data = await response.json();
    setFlags(data);
  };

  const [events, setEvents] = useState([]);
  const [skip, setSkip] = useState(0);
  const [flags, setFlags] = useState([]);
  const limit = 10; // Number of events to fetch per call

  useEffect(() => {
    fetchFlags()
    loadMoreEvents(); // Load initial events
  }, []);

  const loadMoreEvents = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/events?skip=${skip}&limit=${limit}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        }
        );
    const data = await response.json();
    
    setEvents([...events, ...data.events]);
    setSkip(skip + limit);
  };

  return (
    <Grid centered padded>
        <Grid.Row>
            {
            events.length ? 
            <Card.Group style={{margin: '10px'}}>
                {
                events.map((event, index) => (
                <EventCard key={index} event={event} />
                ))
                }
            </Card.Group> :
            <Message info>Come back later...</Message>}
        </Grid.Row>
        <Grid.Row>
            <Button onClick={loadMoreEvents} primary>
                Load More...
            </Button>
        </Grid.Row>
    </Grid>
  );
};

export default EventsFeed;

