import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Table, Button, Icon, Popup, Label, Message, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ResumeModal from './ResumeModal'; // Import the new ResumeModal component

const ProjectApplications = ({ received = false }) => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('ascending');
  const itemsPerPage = 10;

  const truncateText = (text) => {
    if (text.length <= 200) return text;
    return text.substring(0, 200) + '...';
  };

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const endPoint = received ? 'applicationsReceived' : 'applicationsSubmitted';
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/applications/${endPoint}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.status === 404) {
        console.log("No applications found for user");
        setApplications([]);
      } else if (response.ok) {
        const data = await response.json();
        setApplications(data);
      } else {
        throw new Error('Failed to fetch project applications');
      }
    } catch (error) {
      console.error("Failed to fetch applications:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApplicationStatusUpdate = async (appId, status) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/applications/updateApplicationStatus/${appId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ status: status }),
    });

    if (response.ok) {
      toast.success('Updated application status successfully!');
      await fetchApplications();
    } else {
      toast.error('Failed to update application status');
    }
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
    setSortField(field);
    setSortDirection(direction);
  };

  const sortedApplications = [...applications].sort((a, b) => {
    if (!sortField) return 0;
    const aValue = a[sortField];
    const bValue = b[sortField];
    if (aValue < bValue) return sortDirection === 'ascending' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'ascending' ? 1 : -1;
    return 0;
  });

  useEffect(() => {
    fetchApplications();
  }, [received]);

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentApplications = sortedApplications.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(applications.length / itemsPerPage);

  return (
    <>
      {applications.length ? (
        <>
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortField === 'user.fullname' ? sortDirection : null}
                  onClick={() => handleSort('user.fullname')}
                >
                  User
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortField === 'role' ? sortDirection : null}
                  onClick={() => handleSort('role')}
                >
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortField === 'whyInterested' ? sortDirection : null}
                  onClick={() => handleSort('whyInterested')}
                >
                  Why Interested
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortField === 'timeCommitment' ? sortDirection : null}
                  onClick={() => handleSort('timeCommitment')}
                >
                  Time Commitment
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortField === 'status' ? sortDirection : null}
                  onClick={() => handleSort('status')}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortField === 'projectId.projectTitle' ? sortDirection : null}
                  onClick={() => handleSort('projectId.projectTitle')}
                >
                  Title/Background
                </Table.HeaderCell>
                <Table.HeaderCell>Resume</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {currentApplications.map((app) => (
                <Table.Row key={app._id}>
                  <Table.Cell>{app.user.fullname}</Table.Cell>
                  <Table.Cell>{app.role}</Table.Cell>
                  <Table.Cell>{app.whyInterested}</Table.Cell>
                  <Table.Cell>{app.timeCommitment}</Table.Cell>
                  <Table.Cell>{app.status}</Table.Cell>
                  <Table.Cell>
                    <Popup
                      trigger={<Label pointing='right'>{truncateText(app.projectId.projectTitle || app.projectId.background)}</Label>}
                      content={app.projectId.background}
                      wide
                    />
                    <Link to={`/projects/${app.projectId._id}`}>View Project</Link>
                  </Table.Cell>
                  <Table.Cell>
                    <ResumeModal userId={app.user._id} triggerButton={<Button>View Resume</Button>} />
                  </Table.Cell>
                  <Table.Cell>
                    {received ? (
                      <>
                        <Button color='green' disabled={['accepted', 'withdrawn'].includes(app.status)} onClick={() => handleApplicationStatusUpdate(app._id, 'accepted')}>
                          <Icon name='checkmark' /> Accept
                        </Button>
                        <Button color='red' disabled={['rejected', 'withdrawn'].includes(app.status)} onClick={() => handleApplicationStatusUpdate(app._id, 'rejected')}>
                          <Icon name='remove' /> Reject
                        </Button>
                      </>
                    ) : (
                      <Button color='orange' disabled={['accepted', 'withdrawn', 'rejected'].includes(app.status)} onClick={() => handleApplicationStatusUpdate(app._id, 'withdrawn')}>
                        <Icon name='remove' /> Withdraw
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pagination
            activePage={currentPage}
            onPageChange={handlePageChange}
            totalPages={totalPages}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
          />
        </>
      ) : (
        <Message info>
          No applications found for you.
        </Message>
      )}
    </>
  );
};

export default ProjectApplications;
