import React, { useState, useEffect } from 'react';
import { Table, Pagination, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showOnlyWithResume, setShowOnlyWithResume] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams({
                page: currentPage,
                limit: 10,
                hasResume: showOnlyWithResume,
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/users?${queryParams}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                }
            );
            const data = await response.json();
            setUsers(data.users);
            setTotalPages(data.totalPages);
        };

        fetchData();
    }, [currentPage, showOnlyWithResume]);

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleCheckboxChange = (e, { checked }) => {
        setShowOnlyWithResume(checked);
        setCurrentPage(1); // Reset to the first page when the filter changes
    };

    return (
        <div>
            <Checkbox
                label="Show only users with resumes"
                checked={showOnlyWithResume}
                onChange={handleCheckboxChange}
                style={{ marginBottom: '1em' }}
            />
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell>Account Verified</Table.HeaderCell>
                        <Table.HeaderCell>Stripe Subscription</Table.HeaderCell>
                        <Table.HeaderCell>Resume</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map((user, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{user.fullname}</Table.Cell>
                            <Table.Cell>{user.email}</Table.Cell>
                            <Table.Cell>{new Date(user.createdAt).toLocaleDateString()}</Table.Cell>
                            <Table.Cell>{user.accountVerified ? 'Yes' : 'No'}</Table.Cell>
                            <Table.Cell>{user.pro_status}</Table.Cell>
                            <Table.Cell>
                                {user.hasResume ? (
                                    <Link to={`/users/${user.id}`}>View Profile</Link>
                                ) : 'No'}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Pagination
                                totalPages={totalPages}
                                activePage={currentPage}
                                onPageChange={handlePaginationChange}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default UsersTable;
