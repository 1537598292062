import React, { useEffect, useState } from 'react';
import { Card, Accordion, Icon } from 'semantic-ui-react';
import "../App.css"

const TailoredResumes = () => {
    const [tailoredResumes, setTailoredResumes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const fetchTailoredResumes = async () => {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/tailoredResumes`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
              });
              const data = await response.json();
              setTailoredResumes(data);
        };

        fetchTailoredResumes();
    }, []);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    return (
        <Card.Group>
            {tailoredResumes.map((resume, index) => (
                <Card key={index}>
                    <Card.Content>
                        <Card.Header>Tailored Resume for {resume.job.title}</Card.Header>
                        <Card.Meta>Created at {new Date(resume.createdAt).toLocaleDateString()}</Card.Meta>
                        <Accordion styled fluid>
                            <Accordion.Title active={activeIndex === index} index={index} onClick={handleClick}>
                                <Icon name='dropdown' />
                                Tailored Resume
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                                <pre className='pre-wrap'>{resume.tailoredResume}</pre>
                            </Accordion.Content>

                            <Accordion.Title active={activeIndex === index + tailoredResumes.length} index={index + tailoredResumes.length} onClick={handleClick}>
                                <Icon name='dropdown' />
                                Project Suggestion
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index + tailoredResumes.length}>
                                <pre className='pre-wrap'>{resume.projectSuggestion}</pre>
                            </Accordion.Content>
                        </Accordion>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    );
};

export default TailoredResumes;
