import { Icon, Label, Flag } from 'semantic-ui-react';

export const sanitizeString = (input) => {
  // Split the string on underscores
  if (!input) return ''

  const words = input.split('_');

  // Map over each word to capitalize the first letter and lowercase the rest
  const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the capitalized words with a space
  return capitalizedWords.join(' ');
}

export const countries = [
  { name: 'Afghanistan', countryCode: 'af' },
  { name: 'Aland Islands', countryCode: 'ax' },
  { name: 'Albania', countryCode: 'al' },
  { name: 'Algeria', countryCode: 'dz' },
  { name: 'American Samoa', countryCode: 'as' },
  { name: 'Andorra', countryCode: 'ad' },
  { name: 'Angola', countryCode: 'ao' },
  { name: 'Anguilla', countryCode: 'ai' },
  { name: 'Antigua', countryCode: 'ag' },
  { name: 'Argentina', countryCode: 'ar' },
  { name: 'Armenia', countryCode: 'am' },
  { name: 'Aruba', countryCode: 'aw' },
  { name: 'Australia', countryCode: 'au' },
  { name: 'Austria', countryCode: 'at' },
  { name: 'Azerbaijan', countryCode: 'az' },
  { name: 'Bahamas', countryCode: 'bs' },
  { name: 'Bahrain', countryCode: 'bh' },
  { name: 'Bangladesh', countryCode: 'bd' },
  { name: 'Barbados', countryCode: 'bb' },
  { name: 'Belarus', countryCode: 'by' },
  { name: 'Belgium', countryCode: 'be' },
  { name: 'Belize', countryCode: 'bz' },
  { name: 'Benin', countryCode: 'bj' },
  { name: 'Bermuda', countryCode: 'bm' },
  { name: 'Bhutan', countryCode: 'bt' },
  { name: 'Bolivia', countryCode: 'bo' },
  { name: 'Bosnia', countryCode: 'ba' },
  { name: 'Botswana', countryCode: 'bw' },
  { name: 'Bouvet Island', countryCode: 'bv' },
  { name: 'Brazil', countryCode: 'br' },
  { name: 'British Virgin Islands', countryCode: 'vg' },
  { name: 'Brunei', countryCode: 'bn' },
  { name: 'Bulgaria', countryCode: 'bg' },
  { name: 'Burkina Faso', countryCode: 'bf' },
  { name: 'Burma', countryCode: 'mm', alias: 'Myanmar' },
  { name: 'Burundi', countryCode: 'bi' },
  { name: 'Caicos Islands', countryCode: 'tc' },
  { name: 'Cambodia', countryCode: 'kh' },
  { name: 'Cameroon', countryCode: 'cm' },
  { name: 'Canada', countryCode: 'ca' },
  { name: 'Cape Verde', countryCode: 'cv' },
  { name: 'Cayman Islands', countryCode: 'ky' },
  { name: 'Central African Republic', countryCode: 'cf' },
  { name: 'Chad', countryCode: 'td' },
  { name: 'Chile', countryCode: 'cl' },
  { name: 'China', countryCode: 'cn' },
  { name: 'Christmas Island', countryCode: 'cx' },
  { name: 'Cocos Islands', countryCode: 'cc' },
  { name: 'Colombia', countryCode: 'co' },
  { name: 'Comoros', countryCode: 'km' },
  { name: 'Congo', countryCode: 'cd' },
  { name: 'Congo Brazzaville', countryCode: 'cg' },
  { name: 'Cook Islands', countryCode: 'ck' },
  { name: 'Costa Rica', countryCode: 'cr' },
  { name: 'Cote Divoire', countryCode: 'ci' },
  { name: 'Croatia', countryCode: 'hr' },
  { name: 'Cuba', countryCode: 'cu' },
  { name: 'Cyprus', countryCode: 'cy' },
  { name: 'Czech Republic', countryCode: 'cz' },
  { name: 'Denmark', countryCode: 'dk' },
  { name: 'Djibouti', countryCode: 'dj' },
  { name: 'Dominica', countryCode: 'dm' },
  { name: 'Dominican Republic', countryCode: 'do' },
  { name: 'Ecuador', countryCode: 'ec' },
  { name: 'Egypt', countryCode: 'eg' },
  { name: 'El Salvador', countryCode: 'sv' },
  { name: 'England', countryCode: 'gb eng' },
  { name: 'Equatorial Guinea', countryCode: 'gq' },
  { name: 'Eritrea', countryCode: 'er' },
  { name: 'Estonia', countryCode: 'ee' },
  { name: 'Ethiopia', countryCode: 'et' },
  { name: 'Europeanunion', countryCode: 'eu' },
  { name: 'Falkland Islands', countryCode: 'fk' },
  { name: 'Faroe Islands', countryCode: 'fo' },
  { name: 'Fiji', countryCode: 'fj' },
  { name: 'Finland', countryCode: 'fi' },
  { name: 'France', countryCode: 'fr' },
  { name: 'French Guiana', countryCode: 'gf' },
  { name: 'French Polynesia', countryCode: 'pf' },
  { name: 'French Territories', countryCode: 'tf' },
  { name: 'Gabon', countryCode: 'ga' },
  { name: 'Gambia', countryCode: 'gm' },
  { name: 'Georgia', countryCode: 'ge' },
  { name: 'Germany', countryCode: 'de' },
  { name: 'Ghana', countryCode: 'gh' },
  { name: 'Gibraltar', countryCode: 'gi' },
  { name: 'Greece', countryCode: 'gr' },
  { name: 'Greenland', countryCode: 'gl' },
  { name: 'Grenada', countryCode: 'gd' },
  { name: 'Guadeloupe', countryCode: 'gp' },
  { name: 'Guam', countryCode: 'gu' },
  { name: 'Guatemala', countryCode: 'gt' },
  { name: 'Guinea', countryCode: 'gn' },
  { name: 'Guinea-Bissau', countryCode: 'gw' },
  { name: 'Guyana', countryCode: 'gy' },
  { name: 'Haiti', countryCode: 'ht' },
  { name: 'Heard Island', countryCode: 'hm' },
  { name: 'Honduras', countryCode: 'hn' },
  { name: 'Hong Kong', countryCode: 'hk' },
  { name: 'Hungary', countryCode: 'hu' },
  { name: 'Iceland', countryCode: 'is' },
  { name: 'India', countryCode: 'in' },
  { name: 'Indian Ocean Territory', countryCode: 'io' },
  { name: 'Indonesia', countryCode: 'id' },
  { name: 'Iran', countryCode: 'ir' },
  { name: 'Iraq', countryCode: 'iq' },
  { name: 'Ireland', countryCode: 'ie' },
  { name: 'Israel', countryCode: 'il' },
  { name: 'Italy', countryCode: 'it' },
  { name: 'Jamaica', countryCode: 'jm' },
  { name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard' },
  { name: 'Japan', countryCode: 'jp' },
  { name: 'Jordan', countryCode: 'jo' },
  { name: 'Kazakhstan', countryCode: 'kz' },
  { name: 'Kenya', countryCode: 'ke' },
  { name: 'Kiribati', countryCode: 'ki' },
  { name: 'Kuwait', countryCode: 'kw' },
  { name: 'Kyrgyzstan', countryCode: 'kg' },
  { name: 'Laos', countryCode: 'la' },
  { name: 'Latvia', countryCode: 'lv' },
  { name: 'Lebanon', countryCode: 'lb' },
  { name: 'Lesotho', countryCode: 'ls' },
  { name: 'Liberia', countryCode: 'lr' },
  { name: 'Libya', countryCode: 'ly' },
  { name: 'Liechtenstein', countryCode: 'li' },
  { name: 'Lithuania', countryCode: 'lt' },
  { name: 'Luxembourg', countryCode: 'lu' },
  { name: 'Macau', countryCode: 'mo' },
  { name: 'Macedonia', countryCode: 'mk' },
  { name: 'Madagascar', countryCode: 'mg' },
  { name: 'Malawi', countryCode: 'mw' },
  { name: 'Malaysia', countryCode: 'my' },
  { name: 'Maldives', countryCode: 'mv' },
  { name: 'Mali', countryCode: 'ml' },
  { name: 'Malta', countryCode: 'mt' },
  { name: 'Marshall Islands', countryCode: 'mh' },
  { name: 'Martinique', countryCode: 'mq' },
  { name: 'Mauritania', countryCode: 'mr' },
  { name: 'Mauritius', countryCode: 'mu' },
  { name: 'Mayotte', countryCode: 'yt' },
  { name: 'Mexico', countryCode: 'mx' },
  { name: 'Micronesia', countryCode: 'fm' },
  { name: 'Moldova', countryCode: 'md' },
  { name: 'Monaco', countryCode: 'mc' },
  { name: 'Mongolia', countryCode: 'mn' },
  { name: 'Montenegro', countryCode: 'me' },
  { name: 'Montserrat', countryCode: 'ms' },
  { name: 'Morocco', countryCode: 'ma' },
  { name: 'Mozambique', countryCode: 'mz' },
  { name: 'Namibia', countryCode: 'na' },
  { name: 'Nauru', countryCode: 'nr' },
  { name: 'Nepal', countryCode: 'np' },
  { name: 'Netherlands', countryCode: 'nl' },
  { name: 'Netherlandsantilles', countryCode: 'an' },
  { name: 'New Caledonia', countryCode: 'nc' },
  { name: 'New Guinea', countryCode: 'pg' },
  { name: 'New Zealand', countryCode: 'nz' },
  { name: 'Nicaragua', countryCode: 'ni' },
  { name: 'Niger', countryCode: 'ne' },
  { name: 'Nigeria', countryCode: 'ng' },
  { name: 'Niue', countryCode: 'nu' },
  { name: 'Norfolk Island', countryCode: 'nf' },
  { name: 'North Korea', countryCode: 'kp' },
  { name: 'Northern Mariana Islands', countryCode: 'mp' },
  { name: 'Norway', countryCode: 'no' },
  { name: 'Oman', countryCode: 'om' },
  { name: 'Pakistan', countryCode: 'pk' },
  { name: 'Palau', countryCode: 'pw' },
  { name: 'Palestine', countryCode: 'ps' },
  { name: 'Panama', countryCode: 'pa' },
  { name: 'Paraguay', countryCode: 'py' },
  { name: 'Peru', countryCode: 'pe' },
  { name: 'Philippines', countryCode: 'ph' },
  { name: 'Pitcairn Islands', countryCode: 'pn' },
  { name: 'Poland', countryCode: 'pl' },
  { name: 'Portugal', countryCode: 'pt' },
  { name: 'Puerto Rico', countryCode: 'pr' },
  { name: 'Qatar', countryCode: 'qa' },
  { name: 'Reunion', countryCode: 're' },
  { name: 'Romania', countryCode: 'ro' },
  { name: 'Russia', countryCode: 'ru' },
  { name: 'Rwanda', countryCode: 'rw' },
  { name: 'Saint Helena', countryCode: 'sh' },
  { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
  { name: 'Saint Lucia', countryCode: 'lc' },
  { name: 'Saint Pierre', countryCode: 'pm' },
  { name: 'Saint Vincent', countryCode: 'vc' },
  { name: 'Samoa', countryCode: 'ws' },
  { name: 'San Marino', countryCode: 'sm' },
  { name: 'Sandwich Islands', countryCode: 'gs' },
  { name: 'Sao Tome', countryCode: 'st' },
  { name: 'Saudi Arabia', countryCode: 'sa' },
  { name: 'Scotland', countryCode: 'gb sct' },
  { name: 'Senegal', countryCode: 'sn' },
  { name: 'Serbia', countryCode: 'cs' },
  { name: 'Serbia', countryCode: 'rs' },
  { name: 'Seychelles', countryCode: 'sc' },
  { name: 'Sierra Leone', countryCode: 'sl' },
  { name: 'Singapore', countryCode: 'sg' },
  { name: 'Slovakia', countryCode: 'sk' },
  { name: 'Slovenia', countryCode: 'si' },
  { name: 'Solomon Islands', countryCode: 'sb' },
  { name: 'Somalia', countryCode: 'so' },
  { name: 'South Africa', countryCode: 'za' },
  { name: 'South Korea', countryCode: 'kr' },
  { name: 'Spain', countryCode: 'es' },
  { name: 'Sri Lanka', countryCode: 'lk' },
  { name: 'Sudan', countryCode: 'sd' },
  { name: 'Suriname', countryCode: 'sr' },
  { name: 'Swaziland', countryCode: 'sz' },
  { name: 'Sweden', countryCode: 'se' },
  { name: 'Switzerland', countryCode: 'ch' },
  { name: 'Syria', countryCode: 'sy' },
  { name: 'Taiwan', countryCode: 'tw' },
  { name: 'Tajikistan', countryCode: 'tj' },
  { name: 'Tanzania', countryCode: 'tz' },
  { name: 'Thailand', countryCode: 'th' },
  { name: 'Timorleste', countryCode: 'tl' },
  { name: 'Togo', countryCode: 'tg' },
  { name: 'Tokelau', countryCode: 'tk' },
  { name: 'Tonga', countryCode: 'to' },
  { name: 'Trinidad', countryCode: 'tt' },
  { name: 'Tunisia', countryCode: 'tn' },
  { name: 'Turkey', countryCode: 'tr' },
  { name: 'Turkmenistan', countryCode: 'tm' },
  { name: 'Tuvalu', countryCode: 'tv' },
  { name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
  { name: 'Uganda', countryCode: 'ug' },
  { name: 'Ukraine', countryCode: 'ua' },
  { name: 'United Kingdom', countryCode: 'gb', alias: 'uk' },
  { name: 'United States', countryCode: 'us', alias: 'America' },
  { name: 'Uruguay', countryCode: 'uy' },
  { name: 'US Minor Islands', countryCode: 'um' },
  { name: 'US Virgin Islands', countryCode: 'vi' },
  { name: 'Uzbekistan', countryCode: 'uz' },
  { name: 'Vanuatu', countryCode: 'vu' },
  { name: 'Vatican City', countryCode: 'va' },
  { name: 'Venezuela', countryCode: 've' },
  { name: 'Vietnam', countryCode: 'vn' },
  { name: 'Wales', countryCode: 'gb wls' },
  { name: 'Wallis and Futuna', countryCode: 'wf' },
  { name: 'Western Sahara', countryCode: 'eh' },
  { name: 'Yemen', countryCode: 'ye' },
  { name: 'Zambia', countryCode: 'zm' },
  { name: 'Zimbabwe', countryCode: 'zw' },
]

export const getCountryOptions = () => {
  return countries.map(c => {
    return {
      key: `${c.countryCode}-${c.name}`,
      value: c.name,
      text: c.name,
      flag: c.countryCode,
    }
  })
}

export const getCountryIcon = (countryName) => {
  return <Label style={{
    display: 'flex', alignItems: 'center', margin: 2
  }}>
  <Flag name={getFlagCodeFromCountryName(countryName)} />
  <span >{countryName}</span>
</Label>
}

export const getIntentOptions = () => {
  return ['beginner_friendly_portfolio_building', 'portfolio_building', 'startup', 'research', 'non-tech business'].map(val => {
    return {
      key: val,
      value: val,
      text: sanitizeString(val)
    }
  })
}

export const getFlagCodeFromCountryName = (name) => {
  return countries.find(c => c.name === name).countryCode
}

const techMapping = {
  'javascript': 'JavaScript',
  'typescript': 'TypeScript',
  'python': 'Python',
  'java': 'Java',
  'csharp': 'C#',
  'cpp': 'C++',
  'ruby': 'Ruby',
  'php': 'PHP',
  'swift': 'Swift',
  'kotlin': 'Kotlin',
  'go': 'Go',
  'rust': 'Rust',
  'r': 'R',
  'dart': 'Dart',
  'scala': 'Scala',
  'perl': 'Perl',
  'lua': 'Lua',
  'haskell': 'Haskell',
  'elixir': 'Elixir',
  'erlang': 'Erlang',
  'sql': 'SQL',
  'nosql': 'NoSQL Databases',
  'react': 'React',
  'angular': 'Angular',
  'vue': 'Vue.js',
  'svelte': 'Svelte',
  'flutter': 'Flutter',
  'reactnative': 'React Native',
  'node': 'Node.js',
  'django': 'Django',
  'flask': 'Flask',
  'spring': 'Spring',
  'express': 'Express.js',
  'rails': 'Ruby on Rails',
  'laravel': 'Laravel',
  'aspnet': 'ASP.NET',
  'dotnet': '.NET'
};

const courseMappings = {
  'calculus_1': 'Calculus 1',
  'calculus_2': 'Calculus 2',
  'linear_algebra': 'Linear Algebra',
  'discrete_math': 'Discrete Math',
  'data_structures': 'Data Structures',
  'algorithms': 'Algorithms',
};

const degreeMappings =
  {
    'bachelors': "Bachelor's Degree",
    'masters': "Master's Degree",
    'associates': "Associate's Degree",
    'phd': 'Ph.D.',
    'diploma': 'Diploma',
    'certificate': 'Certificate',
    'advanced_diploma': 'Advanced Diploma',
    'postgraduate': 'Postgraduate Degree'
}

const majorMappings = {
  'cs': 'Computer Science',
  'ee': 'Electrical Engineering',
  'me': 'Mechanical Engineering',
  'ce': 'Civil Engineering',
  'chem_eng': 'Chemical Engineering',
  'bio_eng': 'Biomedical Engineering',
  'aero_eng': 'Aerospace Engineering',
  'env_sci': 'Environmental Science',
  'bio': 'Biology',
  'chem': 'Chemistry',
  'phy': 'Physics',
  'math': 'Mathematics',
  'stat': 'Statistics',
  'eco': 'Economics',
  'bus': 'Business Administration',
  'acc': 'Accounting',
  'fin': 'Finance',
  'mkt': 'Marketing',
  'psy': 'Psychology',
  'soc': 'Sociology',
  'pol_sci': 'Political Science',
  'int_rel': 'International Relations',
  'law': 'Law',
  'phil': 'Philosophy',
  'hist': 'History',
  'eng_lit': 'English Literature',
  'art_hist': 'Art History',
  'mus': 'Music',
  'theatre': 'Theatre',
  'edu': 'Education',
  'nurs': 'Nursing',
  'pub_health': 'Public Health',
  'med': 'Medicine',
  'pharm': 'Pharmacy',
  'dent': 'Dentistry',
  'vet': 'Veterinary Medicine',
  'arch': 'Architecture',
  'urban_pl': 'Urban Planning',
  'lang': 'Modern Languages',
  'ling': 'Linguistics',
  'anth': 'Anthropology',
  'geo': 'Geography',
  'earth_sci': 'Earth Sciences',
  'agri': 'Agriculture',
  'food_sci': 'Food Science',
  'nutrition': 'Nutrition',
  'kines': 'Kinesiology',
  'sports_sci': 'Sports Science',
  // More majors can be added as needed
};


const companyTypeMapping = {
  'public': 'Public',
  'seed': 'Seed',
  'series_a': 'Series A',
  'series_b': 'Series B',
  'series_c': 'Series C',
  'series_d+': 'Series D+',
  'private': 'Private',
  'pre_ipo': 'Pre IPO'
}

const projectInvolvementRoleMapping = {
    'engineer': 'Engineer',
    'product_manager':'Product Manager',
    'tech_lead':'Tech Lead',
    'investor': 'Investor',
    'auditor': 'Auditor',
    'student': 'Student',
}

const colors = ['red', 'blue', 'purple', 'orange', 'yellow', 'pink', 'green']

const statusMapping = {
  'applied': 'Applied',
  'applied_with_referral': 'Applied with Referral',
  'interview_in_progress': 'Interview in Progress',
  'offer': 'Offer',
  'rejected': 'Rejected',
  'considering': 'Considering'
}

const interviewStatusMapping = {
  'scheduled': 'Scheduled',
  'awaiting_results': 'Awaiting Results',
  'cleared': 'Cleared',
}

export const shorthandToDisplayTimeline = (shorthand) => {
    const mapping = {
      '1w': '1 week',
      '2w': '2 weeks',
      '3w': '3 weeks',
      '4w': '4 weeks',
      '6w': '6 weeks',
      '6w+': '6+ weeks',
    };
  
    return mapping[shorthand] || shorthand;
  }

  export const displayToShorthandTimeline = (display) => {
    const mapping = {
      '1 week': '1w',
      '2 weeks': '2w',
      '3 weeks': '3w',
      '4 weeks': '4w',
      '6 weeks': '6w',
    };
  
    return mapping[display] || display; // Return the original display value if no mapping is found
  }

  export const mapTechnologyToDisplay = (tech) => {
    return techMapping[tech] || sanitizeString(tech); // Return the original tech if no mapping is found
  }

  export const generateSkillOptions = () => {
    return Object.entries(techMapping).map(([key, value]) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
    }));
  };

  export const generateCourseOptions = () => {
    return Object.entries(courseMappings).map(([key, value]) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
    }));
  };

  export const generateDegreeOptions = () => {
    return Object.entries(degreeMappings).map(([key, value]) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
    }));
  };

  export const generateMajorOptions = () => {
    return Object.entries(majorMappings).map(([key, value]) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
    }));
  };

  export const mapCompanyTypeToDisplay = (companyType) => {
    return companyTypeMapping[companyType] || sanitizeString(companyType); // Return the original tech if no mapping is found
  }

  export const mapMajorMinorToDisplay = (majorMinor) => {
    return majorMappings[majorMinor] || sanitizeString(majorMinor); // Return the original tech if no mapping is found
  }

  export const generateCompanyTypeOptions = () => {
    return Object.entries(companyTypeMapping).map(([key, value], index) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
      label: { color: colors[index%(colors.length)], empty: true, circular: true }
    }));
  };

  export const generateProjectInvolvementRoleOptions = () => {
    return Object.entries(projectInvolvementRoleMapping).map(([key, value], index) => ({
      key: key,
      text: value,
      value: key,
    }));
  }

  export const mapProjectInvolvementRoleToDisplay = (role) => {
    return projectInvolvementRoleMapping[role] || sanitizeString(role)
  }

  export const mapStatusToDisplay = (status) => {
    return statusMapping[status] || status; // Return the original tech if no mapping is found
  }

  export const mapInterviewStatusToDisplay = (status) => {
    return interviewStatusMapping[status] || status; // Return the original tech if no mapping is found
  }

  export const generateStatusOptions = () => {
    return Object.entries(statusMapping).map(([key, value], index) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
      label: { color: colors[index%(colors.length)], empty: true, circular: true }
    }));
  };

  export const generateInterviewStatusOptions = () => {
    return Object.entries(interviewStatusMapping).map(([key, value], index) => ({
      key: key,
      text: value,
      value: key, // or `value` if you prefer the display name as the value in the dropdown
      label: { color: colors[index%(colors.length)], empty: true, circular: true }
    }));
  };

  export const companyTypeToColor = (display) => {  
    const companyTypeColorMapping = {
      'public': 'red',
      'seed': 'orange',
      'series_a': 'yellow',
      'series_b': 'olive',
      'series_c': 'green',
      'series_d+': 'teal',
      'private': 'violet',
      'pre_ipo': 'purple'
    }
    return companyTypeColorMapping[display] || 'grey';
  }

  export const statusToColor = (display) => { 
    const statusColorMapping = {
      'applied': 'teal',
      'applied_with_referral': 'orange',
      'interview_in_progress': 'yellow',
      'offer': 'green',
      'rejected': 'red',
      'considering': 'blue'
    }
    return statusColorMapping[display] || 'grey';
  }

  export const interviewStatusToColor = (display) => {
    const interviewStatusColorMapping = {
      'scheduled': 'yellow',
      'awaiting_results': 'orange',
      'cleared': 'green'
    }
    return interviewStatusColorMapping[display] || 'grey';
  }

  export const jobRoleOptions = () => {
    return [
      { key: 'data_analyst', value: 'data_analyst', text: 'Data Analyst' },
      { key: 'software_engineer', value: 'software_engineer', text: 'Software Engineer' },
      { key: 'product_manager', value: 'product_manager', text: 'Product Manager' },
      { key: 'designer', value: 'designer', text: 'Designer' },
      { key: 'engineering_manager', value: 'engineering_manager', text: 'Engineering Manager' },
    ];
  }

  export const yoeOptions = () => {
    return [
      { key: 'intern', value: 'intern', text: 'Internship' },
      { key: '0_1', value: '0-1', text: '0-1 years' },
      { key: '1_2', value: '1-2', text: '1-2 years' },
      { key: '2_5', value: '2-5', text: '2-5 years' },
      { key: '5_', value: '5+', text: '5+ years' },
    ];
  }
  
  export const createLabel = (color, text, icon='smile', right_floated=false) => {
    let styleObj = {
      display: 'flex', alignItems: 'center', margin: 2
    }
    if (right_floated) {
      styleObj = {
        ...styleObj,
        float: 'right'
      }
    }
    return <Label color={color} style={styleObj}>
      <Icon name={icon} />
      <span >{text}</span>
    </Label>
  }

  export const consolidatedOptions = (options, current_values) => {
    const optionValues = options.map(option => option.value);
    const complement = current_values.filter(value => !optionValues.includes(value));
    const complementOptions = complement.map(value => {
      return {
        key: value.replace(/ /g, "_" ),
        value: value,
        text: value,
      }
    });
    return [...options, ...complementOptions]
  }


export const projectTimelineOptions = () => {
  return [
    { key: '1w', text: '1 week', value: '1w' },
    { key: '2w', text: '2 weeks', value: '2w' },
    { key: '4w', text: '4 weeks', value: '4w' },
    { key: '6w', text: '6 weeks', value: '6w' },
    { key: '6w+', text: '6+ weeks', value: '6w+' },
  ]
}

export const teamSizeOptions = () => {
  return [
    { key: '1', text: '1', value: '1' },
    { key: '2', text: '2', value: '2' },
    { key: '3', text: '3', value: '3' },
    { key: '4', text: '4', value: '4' },
    { key: '4+', text: '4+', value: '4+' },
  ]
}

export const regionOptions = () => {
  return [
      { key: 'usa', text: 'USA', value: 'usa' },
      { key: 'latin_america', text: 'Latin America', value: 'latin_america' },
      { key: 'asia_pacific', text: 'Asia Pacific', value: 'asia_pacific' },
      { key: 'europe', text: 'Europe', value: 'europe' },
      { key: 'africa', text: 'Africa', value: 'africa' },
  ]
}

export const formatParticipants = (participants) => {
  const formattedParticipants = participants.map(participant => {
    const name = participant.user.fullname;
    const role = participant.role;
    return `${name} (${role})`;
});
  return formattedParticipants.join(', ');
}

export const formatParticipantsAsList = (participants) => {
  const formattedParticipants = participants.map(participant => {
    const name = participant.user.fullname;
    const role = participant.role;
    return `${name} (${role})`;
});
  return formattedParticipants;
}

export const mapReferralOptionToDisplay = (referralStance) => {
  const mapping = {
    'wont': "Won't refer",
    'conditional': "Will refer if we work together",
    'noStrings': "Will refer anyone, no strings"
  };

  return mapping[referralStance] || referralStance;
}

export const referralOptions = [
  { key: 'wont', value: 'wont', text: "Won't refer" },
  { key: 'conditional', value: 'conditional', text: "Will refer if we work together" },
  { key: 'noStrings', value: 'noStrings', text: "Will refer no strings" }
];

export const withNoSelection = (optionsGiven) => {
  optionsGiven.push({
      key: 'none',
      value: 'none',
      text: 'No selection'
  })
  return optionsGiven
}

export const getProjectTitle = (project) => {
  return project.projectTitle ? <>
  {project.projectTitle} {['non_tech_business', 'tech_project'].includes(project.projectType) ? <Label color='teal'>
  {project.domain}
  </Label> : null}
  </> :
  ['non_tech_business', 'tech_project'].includes(project.projectType) ? <Label color='teal'>
  {project.domain}
  </Label> : null
}


  