import React, { useState } from 'react';
import { Button, Dropdown, Form, Input, Message, Segment } from 'semantic-ui-react';
import { jobRoleOptions } from '../displayHelpers';
import { toast } from 'react-toastify';

const MassJobsUpload = () => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [error, setError] = useState(false);
    const [jobSource, setJobSource] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [experienceLevel, setExperienceLevel] = useState('');
    const [loading, setLoading] = useState(false)

    const jobSources = [
        { key: 'linkedin', value: 'linkedin', text: 'LinkedIn' },
        { key: 'levels', value: 'levels', text: 'Levels.fyi' },
        { key: 'wellfound', value: 'wellfound', text: 'Wellfound' },
    ];

    const experienceLevelOptions = [
        { key: 'internship', value: 'internship', text: 'Internship' },
        { key: 'entry_level', value: 'entry_level', text: 'Entry Level' },
        { key: 'associate', value: 'associate', text: 'Associate' },
        { key: 'mid_senior', value: 'mid_senior', text: 'Mid-Senior' }
    ];

    const handleFileChange = (event) => {
        let selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "application/json") {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setIsFilePicked(true);
            setError(false);
        } else {
            setError(true);
            setFile(null);
            setIsFilePicked(false);
            setFileName("");
        }
    };

    const handleDropdownChange = (e, { value, name }) => {
        if (name === 'jobSource') {
            setJobSource(value);
        } else if (name === 'jobRole') {
            setJobRole(value);
        } else if (name === 'experienceLevel') {
            setExperienceLevel(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        if (file && jobSource && jobRole && experienceLevel) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('jobSource', jobSource);
            formData.append('jobRole', jobRole);
            formData.append('experienceLevel', experienceLevel);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs_mass/upload`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                    body: formData,  // formData will be the payload
                });

                if (response.ok) {
                    setFile(null);
                    setFileName('');
                    setIsFilePicked(false);
                    setError(false);
                    toast.success('Successfully uploaded file!');
                } else {
                    console.error('Failed to upload file');
                    setError(true);
                    toast.error('There was an error uploading the file');
                }
                setLoading(false)
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('There was an error uploading the file');
                setError(true);
                setLoading(false)
            }
        }
    };

    const handleSoftDelete = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs_mass`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
    
        if (response.ok) {
            toast.success('Soft deleted jobs!');
        } else {
            toast.error('Failed to soft delete jobs');
        }
        setLoading(false)
    }

    const handleHardDelete = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs_mass/delete-recent`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });

        if (response.ok) {
            toast.success('Hard deleted jobs since yesterday!');
        } else {
            toast.error('Failed to hard delete jobs');
        }
        setLoading(false);
    };

    return (
        <Segment loading={loading}>
            <Form onSubmit={handleSubmit} error={error}>
                <Form.Field>
                    <Input
                        type="file"
                        accept=".json"
                        onChange={handleFileChange}
                        label="Upload JSON"
                        placeholder="Upload your .json file"
                    />
                </Form.Field>
                {isFilePicked && (
                    <Message>
                        <Message.Header>File Selected:</Message.Header>
                        <p>{fileName}</p>
                    </Message>
                )}
                <Form.Field>
                    <Dropdown
                        placeholder='Select Job Source'
                        fluid
                        selection
                        options={jobSources}
                        onChange={(e, data) => handleDropdownChange(e, {...data, name: 'jobSource'})}
                        value={jobSource}
                        name='jobSource'
                    />
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        placeholder='Select Job Role'
                        fluid
                        selection
                        options={jobRoleOptions()}
                        onChange={(e, data) => handleDropdownChange(e, {...data, name: 'jobRole'})}
                        value={jobRole}
                        name='jobRole'
                    />
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        placeholder='Select Experience Level'
                        fluid
                        selection
                        options={experienceLevelOptions}
                        onChange={(e, data) => handleDropdownChange(e, {...data, name: 'experienceLevel'})}
                        value={experienceLevel}
                        name='experienceLevel'
                    />
                </Form.Field>
                {error && (
                    <Message error header="File Error" content="Please upload a valid JSON file." />
                )}
                <Button type="submit" disabled={!isFilePicked || !jobSource || !jobRole || !experienceLevel}>Submit</Button>
            </Form>
            <Segment>
                <Button color="orange" onClick={handleSoftDelete}>
                    Soft Delete Jobs
                </Button>
                <Button color="red" onClick={handleHardDelete}>
                    Hard Delete Jobs Since Yesterday
                </Button>
            </Segment>
        </Segment>
    );
};

export default MassJobsUpload;
