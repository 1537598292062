import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Table, Button, Icon, Message, Modal } from 'semantic-ui-react';
import ResumeModal from './ResumeModal'; // Import the ResumeModal component

const ProjectApplicationsForProject = ({ projectId, open, onClose }) => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('ascending');
  const itemsPerPage = 10;

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/applications/applicationsReceivedForProject/${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.status === 404) {
        console.log("No applications found for project");
        setApplications([]);
      } else if (response.ok) {
        const data = await response.json();
        setApplications(data);
      } else {
        throw new Error('Failed to fetch project applications');
      }
    } catch (error) {
      console.error("Failed to fetch applications:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApplicationStatusUpdate = async (appId, status) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/applications/updateApplicationStatus/${appId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ status: status }),
    });

    if (response.ok) {
      toast.success('Updated application status successfully!');
      await fetchApplications();
    } else {
      toast.error('Failed to update application status');
    }
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
    setSortField(field);
    setSortDirection(direction);
  };

  const sortedApplications = [...applications].sort((a, b) => {
    if (!sortField) return 0;
    const aValue = a[sortField];
    const bValue = b[sortField];
    if (aValue < bValue) return sortDirection === 'ascending' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'ascending' ? 1 : -1;
    return 0;
  });

  useEffect(() => {
    if (open) {
      fetchApplications();
    }
  }, [open]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentApplications = sortedApplications.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(applications.length / itemsPerPage);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Project Applications</Modal.Header>
      <Modal.Content>
        {applications.length ? (
          <>
            <Table celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortField === 'user.fullname' ? sortDirection : null}
                    onClick={() => handleSort('user.fullname')}
                  >
                    User
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortField === 'role' ? sortDirection : null}
                    onClick={() => handleSort('role')}
                  >
                    Role
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortField === 'whyInterested' ? sortDirection : null}
                    onClick={() => handleSort('whyInterested')}
                  >
                    Why Interested
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortField === 'timeCommitment' ? sortDirection : null}
                    onClick={() => handleSort('timeCommitment')}
                  >
                    Time Commitment
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortField === 'status' ? sortDirection : null}
                    onClick={() => handleSort('status')}
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell>Resume</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {currentApplications.map((app) => (
                  <Table.Row key={app._id}>
                    <Table.Cell>{app.user.fullname}</Table.Cell>
                    <Table.Cell>{app.role}</Table.Cell>
                    <Table.Cell>{app.whyInterested}</Table.Cell>
                    <Table.Cell>{app.timeCommitment}</Table.Cell>
                    <Table.Cell>{app.status}</Table.Cell>
                    <Table.Cell>
                      <ResumeModal userId={app.user._id} triggerButton={<Button>View Resume</Button>} />
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        <Button color='green' disabled={['accepted', 'withdrawn'].includes(app.status)} onClick={() => handleApplicationStatusUpdate(app._id, 'accepted')}>
                          <Icon name='checkmark' /> Accept
                        </Button>
                        <Button color='red' disabled={['rejected', 'withdrawn'].includes(app.status)} onClick={() => handleApplicationStatusUpdate(app._id, 'rejected')}>
                          <Icon name='remove' /> Reject
                        </Button>
                      </>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        ) : (
          <Message info>
            No applications found for this project.
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectApplicationsForProject;
