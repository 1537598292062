import React from 'react';
import { Container, Segment, Icon, Divider } from 'semantic-ui-react';

const Footer = () => {

    const textStyle = {
        color: 'gray', // Set text color to gray
        fontSize: '0.8rem' // Set font size smaller
    };
  return (
    <Segment vertical style={{ padding: '2em 0em', marginTop: '2em', position: 'sticky', bottom: 0 }}>
        <Divider/>
      <Container textAlign='center' style={textStyle}>
        Follow us on social media:
        <div style={{ marginTop: '1em' }}>
          <a href="https://www.youtube.com/channel/UCxZta6dvWHvE6vafN3I2W_Q" target="_blank" rel="noopener noreferrer">
            <Icon name='youtube' size='large' />
          </a>
          <a href="https://www.tiktok.com/@remoteree" target="_blank" rel="noopener noreferrer">
            <Icon name='music' size='large' />
          </a>
            <a href="https://www.linkedin.com/in/reshad-bin-harun-b6450b122/" target="_blank" rel="noopener noreferrer">
            <Icon name='linkedin' size='large' />
          </a>
          <a href="https://www.instagram.com/remoteree/" target="_blank" rel="noopener noreferrer">
            <Icon name='instagram' size='large' />
          </a>
        </div>
        <br/>
        <p>KhwajaLabs is a <strong>RemoteRee</strong> project. Please contact reeremote@gmail.com for any concerns/comments.</p>
      </Container>
    </Segment>
  );
};

export default Footer;
