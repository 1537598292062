import React, { useState, useEffect } from 'react';
import { Button, Form, Segment, Icon, Label, Header, Popup, Divider } from 'semantic-ui-react';
import { generateSkillOptions } from '../displayHelpers'; // Assuming this function returns tech options

const Contributions = ({ onContributionsChange, type, parentIndex }) => {
  const [contributions, setContributions] = useState([]);
  const [newContribution, setNewContribution] = useState({
    description: '',
    technologiesUsed: [],
    impact: '',
    demoLink: ''
  });

  // Notify parent component whenever contributions list changes
  useEffect(() => {
    // Instead of directly passing contributions, pass a function to retrieve them
    onContributionsChange(contributions);
  }, [contributions]);

  const handleAddContribution = (event) => {
    event.preventDefault()
    setContributions([...contributions, newContribution]);
    setNewContribution({ description: '', technologiesUsed: [], impact: '', demoLink: '' }); // Reset form
  };

  const handleRemoveContribution = (index) => {
    setContributions(contributions.filter((_, i) => i !== index));
  };

  const handleInputChange = (field, value) => {
    setNewContribution({ ...newContribution, [field]: value });
  };

  const handleAddition = (e, { value }) => {
    // Add the new option to the options array
    setOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
  };


  const [options, setOptions] = useState(generateSkillOptions());

  return (
    <Segment color='purple'>
        <Header as='h4'>Add a contribution for project</Header>
      <Form>
      <Form.Input
          fluid
          label={
            <label>
              Description
              <Popup
                content={
                  <div>
                    <p>Choose a concise title that reflects the essence of your project. Think about the outcome or the main problem it solved. For example:</p>
                    <p><strong>Example:</strong> "Inventory Management System - Automating Stock Replenishment"</p>
                  </div>
                }
                trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              />
            </label>
          }
          placeholder="Description"
          value={newContribution.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
        />
        <Form.Field>
          <label>
            Technologies Used
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Include all key technologies, frameworks, and tools used in this project. This helps to showcase your technical skills and proficiencies.</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Dropdown
            placeholder="Select Technologies"
            fluid multiple selection
            search
            options={options}
            onAddItem={handleAddition}
            value={newContribution.technologiesUsed}
            allowAdditions={true}
            onChange={(e, { value }) => handleInputChange('technologiesUsed', value)}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Impact
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Describe the direct impact of your contribution. Focus on results, achievements, or value added to the project. Use quantifiable metrics where possible. For example:</p>
                  <p><strong>Example:</strong> Reduced load time by 30% through optimization, enhancing user experience and increasing daily active users.</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Input
            fluid placeholder="Impact"
            value={newContribution.impact}
            onChange={(e) => handleInputChange('impact', e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Demo Link
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Provide a link to a live demo of the project or to its code repository. This can include links to GitHub repositories, hosted demo versions, or other platforms where the project can be viewed or interacted with.</p>
                  <p><strong>Example:</strong> https://github.com/yourusername/projectname or https://projectname.demo.com</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Input
            fluid placeholder="Demo Link (Optional)"
            value={newContribution.demoLink}
            onChange={(e) => handleInputChange('demoLink', e.target.value)}
          />
        </Form.Field>
        <Button onClick={handleAddContribution}>Add this contribution to {type} #{parentIndex}</Button>
      </Form>
      {contributions.length > 0 ? <Divider /> : null}
      {contributions && contributions.map((contribution, index) => {
        let dataForDisplay = []
        if (contribution.description) {
          dataForDisplay.push(contribution.description)
        }
        if (contribution.technologiesUsed.length) {
          dataForDisplay.push(`Technologies used: ${contribution.technologiesUsed}`)
        }
        if (contribution.impact) {
          dataForDisplay.push(`Impact: ${contribution.impact}`)
        }
        if (contribution.demoLink) {
          dataForDisplay.push(`Demo Link: ${contribution.demoLink}`)
        }
        return (
          <>
            <Label key={index}>
              {dataForDisplay.join(' | ')}
              <Icon name="delete" onClick={() => handleRemoveContribution(index)} />
            </Label>
            <Divider />
          </>
          )
        }
      )
    }
    </Segment>
  );
};

export default Contributions;
