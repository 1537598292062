import React from 'react';
import { Grid, Header, Icon, List, Divider, Container } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const features = [
  { name: 'Search fresh tech jobs', availableInFree: true, availableInPremium: true },
  { name: 'Participate in free-tier eligible projects with peers', availableInFree: true, availableInPremium: true },
  { name: 'Guided resume building', availableInFree: true, availableInPremium: true },
  { name: 'Track upto 10 job applications', availableInFree: true, availableInPremium: true },
  { name: 'Guided strategy on job applications and interview process', availableInFree: true, availableInPremium: true },
  { name: 'Search newest tech jobs (added and reset daily)', availableInFree: false, availableInPremium: true },
  { name: 'Unlimited job tracking', availableInFree: false, availableInPremium: true },
  { name: 'Company insights for job search', availableInFree: false, availableInPremium: true },
  { name: 'Access to pro projects', availableInFree: false, availableInPremium: true },
  { name: 'AI-guided live instruction for projects', availableInFree: false, availableInPremium: true },
  { name: 'Unlimited formatted Resume downloads', availableInFree: false, availableInPremium: true },
];

const Pricing = () => {
  return (
    <Container>
      <Header as='h2' icon textAlign='center'>
        <Icon name='users' circular />
        <Header.Content>Product Offering</Header.Content>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            <Header as='h3'>Free Subscription</Header>
            <List>
              {features.map((feature) => (
                <List.Item key={feature.name}>
                  <Icon name={feature.availableInFree ? 'check' : 'close'} color={feature.availableInFree ? 'green' : 'red'} />
                  {feature.name}
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column width={2}>
            <Divider vertical>VS</Divider>
          </Grid.Column>

          <Grid.Column width={7}>
            <Header as='h3'>Pro Subscription $5.99/mo</Header>
            <List>
              {features.map((feature) => (
                <List.Item key={feature.name}>
                  <Icon name={feature.availableInPremium ? 'check' : 'close'} color={feature.availableInPremium ? 'green' : 'red'} />
                  {feature.name}
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Pricing;
