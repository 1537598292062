import React, { useState, useEffect } from 'react';
import { Button, Form, Segment, Icon, Label, Header, Divider, Popup } from 'semantic-ui-react';
import { generateSkillOptions } from '../displayHelpers'; // Assuming this function returns tech options
import { toast } from 'react-toastify';

const ContributionsEdit = ({projectId, modelType}) => {
  const [contributions, setContributions] = useState([]);
  const [newContribution, setNewContribution] = useState({
    description: '',
    teamMembers: '',
    technologiesUsed: [],
    impact: '',
    demoLink: ''
  });

  const fetchContributions = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/contributions/${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
  
      const data = await response.json();
      setContributions(data)
  }

  const deleteContribution = async (contribId) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/contribution/${contribId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
  
      if (response.ok) {
        toast.success('Deleted contribution successfully!');
        await fetchContributions()
      } else {
        toast.error('Failed to delete contribution');
      }
  }

  const addContribution = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/contribution`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ contribution: {...newContribution, ...{project: projectId, onModel: modelType} }}),
      });
  
      if (response.ok) {
        toast.success('Added contribution successfully!');
        await fetchContributions()
      } else {
        toast.error('Failed to add contribution');
      }
  }

  useEffect(() => {
    // TODO: fetch contributions by Project id
    const fetchData = async () => {
        await fetchContributions();
      };
      fetchData();
  }, [])

  const handleAddContribution = async (event) => {
    event.preventDefault()
    await addContribution()
    setNewContribution({ description: '', teamMembers: '', technologiesUsed: [], impact: '', demoLink: '' }); // Reset form
  };

  const handleRemoveContribution = async (contribId) => {
    await deleteContribution(contribId)
  };

  const handleInputChange = (field, value) => {
    setNewContribution({ ...newContribution, [field]: value });
  };

  const handleAddition = (e, { value }) => {
    // Add the new option to the options array
    setOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
  };


  const [options, setOptions] = useState(generateSkillOptions());

  return (
    <Segment color='purple'>
        <Header as='h4'>Add a contribution for project</Header>
      <Form>
      <Form.Input
          fluid
          label={
            <label>
              Description
              <Popup
                content={
                  <div>
                    <p>Choose a concise title that reflects the essence of your project. Think about the outcome or the main problem it solved. For example:</p>
                    <p><strong>Example:</strong> "Inventory Management System - Automating Stock Replenishment"</p>
                  </div>
                }
                trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              />
            </label>
          }
          placeholder="Description"
          value={newContribution.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
        />
        <Form.Field>
          <label>
            Technologies Used
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Include all key technologies, frameworks, and tools used in this project. This helps to showcase your technical skills and proficiencies.</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Dropdown
            placeholder="Select Technologies"
            fluid multiple selection
            search
            options={options}
            onAddItem={handleAddition}
            value={newContribution.technologiesUsed}
            allowAdditions={true}
            onChange={(e, { value }) => handleInputChange('technologiesUsed', value)}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Impact
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Describe the direct impact of your contribution. Focus on results, achievements, or value added to the project. Use quantifiable metrics where possible. For example:</p>
                  <p><strong>Example:</strong> Reduced load time by 30% through optimization, enhancing user experience and increasing daily active users.</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Input
            fluid placeholder="Impact"
            value={newContribution.impact}
            onChange={(e) => handleInputChange('impact', e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Demo Link
            <Popup
              trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
              content={
                <div>
                  <p>Provide a link to a live demo of the project or to its code repository. This can include links to GitHub repositories, hosted demo versions, or other platforms where the project can be viewed or interacted with.</p>
                  <p><strong>Example:</strong> https://github.com/yourusername/projectname or https://projectname.demo.com</p>
                </div>
              }
              wide='very'
            />
          </label>
          <Form.Input
            fluid placeholder="Demo Link (Optional)"
            value={newContribution.demoLink}
            onChange={(e) => handleInputChange('demoLink', e.target.value)}
          />
        </Form.Field>
        <Button onClick={handleAddContribution}>Add Contribution</Button>
      </Form>
      {contributions.length ? <Divider /> : null}
      {contributions && contributions.map((contribution, index) => {
        let dataForDisplay = []
        dataForDisplay.push(`#${index+1}`)
        if (contribution.description) {
          dataForDisplay.push(contribution.description)
        }
        if (contribution.technologiesUsed.length) {
          dataForDisplay.push(`Technologies used: ${contribution.technologiesUsed}`)
        }
        if (contribution.impact) {
          dataForDisplay.push(`Impact: ${contribution.impact}`)
        }
        if (contribution.demoLink) {
          dataForDisplay.push(`Demo Link: ${contribution.demoLink}`)
        }
        return (
          <>
            <Label color="olive" key={index}>
            {dataForDisplay.join(' | ')}
            <Icon size="large" name="delete" color="red" onClick={() => handleRemoveContribution(contribution._id)} />
          </Label>
          <Divider />
          </>
        ) 
        }
      )
    }
    </Segment>
  );
};

export default ContributionsEdit;
