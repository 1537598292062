import React, { useState, useEffect } from 'react';
import { Accordion, Icon, Label, Form, TextArea, Button, List, Container } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const TaskCourseMaterial = ({ taskId, isCreator }) => {
  const [courseTask, setCourseTask] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [editedTopicsMaterial, setEditedTopicsMaterial] = useState('');
  const [editedAdditionalContext, setEditedAdditionalContext] = useState('');

  useEffect(() => {
    const fetchCourseTask = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/courseTask/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const data = await response.json();
        setCourseTask(data);
        setEditedTopicsMaterial(data.topics_material);
        setEditedAdditionalContext(data.additional_context_material);
        // Logic to determine if user is the project creator
      } catch (error) {
        console.error('Error fetching course task:', error);
      }
    };
    fetchCourseTask();
  }, [taskId]);

  const handleAccordionClick = (index) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/courseTasks/${courseTask._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          topics_material: editedTopicsMaterial,
          additional_context_material: editedAdditionalContext,
        }),
      });

      if (response.ok) {
        toast.success('Course task updated successfully!');
      } else {
        toast.error('Failed to update course task');
      }
    } catch (error) {
      console.error('Error updating course task:', error);
      toast.error('Failed to update course task');
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/question/${questionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.ok) {
        toast.success('Question deleted successfully!');
        setCourseTask({
          ...courseTask,
          assessment: {
            ...courseTask.assessment,
            questions: courseTask.assessment.questions.filter(question => question._id !== questionId),
          },
        });
      } else {
        toast.error('Failed to delete question');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error('Failed to delete question');
    }
  };

  return courseTask ? (
    <Container>
      <Label as="a" color="blue" ribbon>
        Topics Material
      </Label>
      <Form>
        {isCreator ? (
          <TextArea
            value={editedTopicsMaterial}
            onChange={(e) => setEditedTopicsMaterial(e.target.value)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: courseTask.topics_material }} />
        )}
      </Form>
      
      <Label as="a" color="blue" ribbon>
        Additional Context
      </Label>
      <Form>
        {isCreator ? (
          <TextArea
            value={editedAdditionalContext}
            onChange={(e) => setEditedAdditionalContext(e.target.value)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: courseTask.additional_context_material }} />
        )}
      </Form>

      <Accordion styled style={{marginTop: '5px'}}>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={() => handleAccordionClick(1)}
        >
          <Icon name='dropdown' />
          Course Assessment Questions
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <List>
            {courseTask.assessment.questions.map((question) => (
              <List.Item key={question._id}>
                <List.Content floated="right">
                  {isCreator && (
                    <Button
                      icon="delete"
                      color="red"
                      onClick={() => handleDeleteQuestion(question._id)}
                    />
                  )}
                </List.Content>
                <List.Content>
                  <List.Header>{question.question}</List.Header>
                  <List.Description>
                    {question.answers.map((answer, index) => (
                      <div key={index}>
                        {answer.answer} {answer.correct && <Icon name="check" color="green" />}
                      </div>
                    ))}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Accordion.Content>
      </Accordion>

      {isCreator && (
        <Button color="green" onClick={handleSave} style={{margin: '5px 0'}}>
          Save Changes
        </Button>
      )}
    </Container>
  ) : (
    <Container>Loading...</Container>
  );
};

export default TaskCourseMaterial;
