import React, { useState, useEffect } from 'react';
import { Checkbox, Segment, Form, Button, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const FeatureFlags = () => {
  const [flags, setFlags] = useState([]);
  const [newFlagName, setNewFlagName] = useState('');
  const [newFlagEnabled, setNewFlagEnabled] = useState(false);

  useEffect(() => {
    // Fetch feature flags
    const fetchFlags = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
      }
      );
      const data = await response.json();
      setFlags(data);
    };

    fetchFlags();
  }, []);

  const handleToggle = async (name, isEnabled) => {
    // Update feature flag
    await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags/${name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ isEnabled: !isEnabled }),
    });

    // Optimistically update UI
    setFlags(flags.map(flag => flag.name === name ? { ...flag, isEnabled: !isEnabled } : flag));
  };

  const handleAddFlag = async () => {
    // Create a new feature flag
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ name: newFlagName, isEnabled: newFlagEnabled }),
    });

    if (response.ok) {
      const newFlag = await response.json();
      setFlags([...flags, newFlag]);
      setNewFlagName('');
      setNewFlagEnabled(false);
      toast.success('Feature flag added successfully!');
    } else {
      // Handle error (e.g., flag already exists)
      console.error('Failed to add new flag');
      toast.error('Failed to add feature flag');
    }
  };


  return (
    <Segment>
        <Form onSubmit={handleAddFlag}>
            <Form.Field>
            <Input
                placeholder='Feature Flag Name'
                value={newFlagName}
                onChange={e => setNewFlagName(e.target.value)}
            />
            </Form.Field>
            <Form.Field>
            <Checkbox
                label='Is Enabled'
                checked={newFlagEnabled}
                onChange={(e, { checked }) => setNewFlagEnabled(checked)}
            />
            </Form.Field>
            <Button type='submit' positive>Add Flag</Button>
        </Form>
      {flags.map(flag => (
        <div key={flag.name}>
          <Checkbox
            label={flag.name}
            checked={flag.isEnabled}
            onChange={() => handleToggle(flag.name, flag.isEnabled)}
          />
        </div>
      ))}
    </Segment>
  );
};

export default FeatureFlags;
