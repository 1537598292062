import { Menu, Icon } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import React, {useState, useEffect } from 'react';
import { allowFeature } from './feature_flags_service';
import "./App.css"

const MenuBar = ({flags, isLoggedIn, isAdmin, unreadCount}) => {
    const location = useLocation(); // Get the current location
    const [activeItem, setActiveItem] = useState(location.pathname); // Set the active item based on the current path

  useEffect(() => {
    setActiveItem(location.pathname); // Update active item when the location changes
  }, [location])

    const handleItemClick = (e, { name }) => setActiveItem(name);

    return (
      isLoggedIn && <Menu pointing secondary size='large' stackable>
      <Menu.Item
         name="profile"
         active={activeItem === '/profile'}
         onClick={handleItemClick}
         as={Link}
         to="/profile"
       >
         Profile
       </Menu.Item>
       {
        (isAdmin || allowFeature(flags,'projects')) && <Menu.Item
         name="projects"
         active={activeItem === '/projects'}
         onClick={handleItemClick}
         as={Link}
         to="/projects"
       >
         Projects
       </Menu.Item>
       }
       <Menu.Item
         name="jobs"
         active={activeItem === '/jobs'}
         onClick={handleItemClick}
         as={Link}
         to="/jobs"
       >
         Jobs
       </Menu.Item>
       <Menu.Item
         name="users"
         active={activeItem === '/users'}
         onClick={handleItemClick}
         as={Link}
         to="/users"
       >
         Users
       </Menu.Item>
        {isAdmin ? <Menu.Item
         name="admin_console"
         active={activeItem === '/admin_console'}
         onClick={handleItemClick}
         as={Link}
         to="/admin_console"
       >
         Admin Console
       </Menu.Item> : null
       }
        <Menu.Item
            name="notifications"
            active={activeItem === '/notifications'}
            onClick={handleItemClick}
            as={Link}
            to="/notifications"
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                Notifications
                {(unreadCount && unreadCount > 0) ? (
                    <span className='notification-count'>
                        {unreadCount}
                    </span>
                ) : null}
            </div>
        </Menu.Item>
     </Menu>
    )
  }
  export default MenuBar;

  