import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import ProjectsList from './ProjectsList';
import MyGarage from './MyGarage';

const Projects = ({isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState('allProjects');
  const navigate = useNavigate(); // Initialize navigate function

  // Updated handleTabClick to navigate to new routes
  const handleTabClick = (e, { name }) => {
    setActiveTab(name);
    navigate(`/${name}`); // Navigate to the respective route
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'allProjects':
        return <ProjectsList />;
      case 'myGarage':
        return <MyGarage/>;
      default:
        return null;
    }
  };

  return (
    isLoggedIn ? <Segment>
     <Menu attached='top' tabular>
        <Menu.Item
          name='allProjects'
          active={activeTab === 'allProjects'}
          onClick={handleTabClick}
        >
          Projects
        </Menu.Item>
        <Menu.Item
          name='myGarage'
          active={activeTab === 'myGarage'}
          onClick={handleTabClick}
        >
          My Garage
        </Menu.Item>
      </Menu>

      <Segment attached='bottom'>
        {renderActiveTabContent()}
      </Segment>
    </Segment> :
    null
  );
};

export default Projects;
