import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics"; // Import isSupported for checking analytics support

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

let analytics;
// Check if Firebase Analytics is supported (useful for SSR or environments without analytics support)
isSupported().then((isSupported) => {
    if (isSupported && process.env.REACT_APP_NODE_ENV === 'production') {
        analytics = getAnalytics(app); // Only initialize analytics in production
    } else {
        console.log("Firebase Analytics not initialized due to unsupported environment or non-production mode");
    }
});

export { auth, provider, analytics };
