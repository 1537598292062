export const getOptions = async (category) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/options/${category}`)
    if (!response.ok) {
        console.error(`Error fetching ${category} options`)
        return
    };
    return (await response.json());
}

export const addOption = async (category, value) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/options/${category}`,
    {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ value }),
    })
    if (!response.ok) {
        console.error(`Error adding ${category} value`)
    };
    console.log(`Added new option for ${category}`)
    return
}