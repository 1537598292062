import React, { useState } from 'react';
import { Accordion, Form, Input, TextArea, Icon, Label, Button } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import TopicInput from './TopicInput';

const MAX_TOPICS = 10;

const ResourceLinkInput = ({ onAddResourceLink, resourceLinks }) => {
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');

  const handleAddResourceLink = (e) => {
    e.preventDefault(); // Prevent form submission
    if (description && url && resourceLinks.length < MAX_TOPICS) {
      onAddResourceLink({ key: description, value: url });
      setDescription(''); // Clear input after adding
      setUrl('');
    }
  };

  return (
    <Form.Field>
      <Input
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Input
        placeholder="URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        action={{
          color: 'teal',
          icon: 'add',
          onClick: handleAddResourceLink,
          content: 'Add'
        }}
      />
    </Form.Field>
  );
};

const AddNewTaskForExistingTimelineForm = ({ projectTimelineId, refetchProjectDetails }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const [topics, setTopics] = useState([]);
  const [additionalContext, setAdditionalContext] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [resourceLinks, setResourceLinks] = useState([]);

  const handleAddTopic = (topic) => {
    setTopics([...topics, topic]);
  };

  const handleRemoveTopic = (index) => {
    setTopics(topics.filter((_, i) => i !== index));
  };

  const handleAddResourceLink = (resourceLink) => {
    setResourceLinks([...resourceLinks, resourceLink]);
  };

  const handleRemoveResourceLink = (index) => {
    setResourceLinks(resourceLinks.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      projectTimelineId,
      content,
      description,
      topics_to_cover: topics,
      additionalContext,
      start: startDate,
      end: endDate,
      resourceLinksMap: resourceLinks.reduce((acc, link) => {
        acc[link.key] = link.value;
        return acc;
      }, {}),
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      toast.success('Task added successfully!');
      setActiveIndex(-1); // Collapse the accordion on successful submission
      // Clear the form fields
      setContent('');
      setDescription('');
      setTopics([]);
      setAdditionalContext('');
      setStartDate(new Date());
      setEndDate(new Date());
      setResourceLinks([]);
      refetchProjectDetails()
    } else {
      toast.error('Failed to add task');
    }
  };

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <Accordion fluid styled>
      <Accordion.Title active={activeIndex === 0} onClick={() => handleAccordionClick(0)}>
        <Icon name='dropdown' />
        Add New Task
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <Form onSubmit={handleSubmit}>
          <Form.Field required>
            <label>Content</label>
            <Input
              placeholder="Content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Topics to Cover</label>
            <TopicInput onAddTopic={handleAddTopic} topics={topics} />
            <div>
              {topics.map((topic, index) => (
                <Label key={index} style={{ marginBottom: '5px' }}>
                  {topic}
                  <Icon name="delete" onClick={() => handleRemoveTopic(index)} />
                </Label>
              ))}
            </div>
          </Form.Field>
          <Form.Field>
            <label>Additional Context</label>
            <TextArea
              placeholder="Additional Context"
              value={additionalContext}
              onChange={(e) => setAdditionalContext(e.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Start Date</label>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Form.Field>
          <Form.Field required>
            <label>End Date</label>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Field>
          <Form.Field>
            <label>Resource Links</label>
            <ResourceLinkInput onAddResourceLink={handleAddResourceLink} resourceLinks={resourceLinks} />
            <div>
              {resourceLinks.map((link, index) => (
                <Label key={index} style={{ marginBottom: '5px' }}>
                  {link.key} - {link.value}
                  <Icon name="delete" onClick={() => handleRemoveResourceLink(index)} />
                </Label>
              ))}
            </div>
          </Form.Field>
          <Button disabled={!content || !startDate || !endDate} type="submit">Submit</Button>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default AddNewTaskForExistingTimelineForm;
