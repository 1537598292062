import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Header, Icon, Message, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FeatureFlags from './FeatureFlags';
import PaymentCheckoutForm from './PaymentCheckoutForm';
import { allowFeature } from '../feature_flags_service';

const Account = ({ isAdmin, isLoggedIn }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState('');
  const [membershipPlan, setMembershipPlan] = useState('');
  const [flags, setFlags] = useState([]);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchFlags = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
    {
      headers: {
          'Content-Type': 'application/json',
        },
    }
    );
    const data = await response.json();
    setFlags(data);
  };

  const handlePasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ newPassword }),
    });

    if (response.ok) {
      setNewPassword('');
      setConfirmPassword('');
      setError(''); // Clear any existing errors
      toast.success('Password updated successfully!');
    } else {
      const responseData = await response.json();
      setApiError(responseData.message || 'Failed to update password');
      toast.error('Failed to update password!');
    }
  };

  const deleteAccount = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/delete-account`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });

    if (response.ok) {
        setModalOpen(false);
        // Perform cleanup, redirect to login page, or show confirmation message
        toast.success('Account deleted successfully!');
        localStorage.removeItem('authToken');
        setTimeout(() => {
          navigate('/login');
          window.location.reload();
        }, 3000);
    } else {
      const responseData = await response.json();
      setApiError(responseData.message || 'Failed to delete account');
      toast.error('Failed to delete account, please contact reeremote@gmail.com!');
    }
  };

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      // Assuming you have an endpoint to fetch user details including subscription info
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/profile`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMembershipPlan(data.membership_type);
      }
    };

    if (isLoggedIn) {
      fetchSubscriptionDetails();
    }
    fetchFlags();
  }, [isLoggedIn]);

  const handleSubscribe = async () => {
    setCheckoutModalOpen(true);
  };

  const handleCancelSubscription = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/payments/cancel-subscription`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });

    if (response.ok) {
        // Perform cleanup, redirect to login page, or show confirmation message
        toast.success('Cancelled subscription successfully!');
        localStorage.removeItem('isPro')
        window.location.reload();
    } else {
      toast.error('Failed to cancel subscription, please contact reeremote@gmail.com!');
    }
  };

  return (
    isLoggedIn ? (
      <div>
        <Segment>
          <Form error={!!error || !!apiError}>
            <Form.Field>
              <label>New Password</label>
              <Input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={handlePasswordChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Confirm New Password</label>
              <Input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </Form.Field>
            {error && <Message error content={error} />}
            {apiError && <Message error content={apiError} />}
            <Button positive onClick={updatePassword}>Update Password</Button>
          </Form>
        </Segment>

        <Segment>
          <Button color="red" onClick={() => setModalOpen(true)}>Delete Account</Button>

          <Modal
            basic
            dimmer="blurring"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            size="small"
          >
            <Header icon>
              <Icon name="archive" />
              Confirm Account Deletion
            </Header>
            <Modal.Content>
              <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color="red" inverted onClick={() => setModalOpen(false)}>
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={() => { setModalOpen(false); deleteAccount(); }}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </Segment>
        {
          allowFeature(flags, 'pro_plan') && (
            <Segment>
              {membershipPlan === 'pro' ? (
                <>
                  <p>Current Subscription Plan: {membershipPlan}</p>
                  <Button negative onClick={handleCancelSubscription}>Cancel Subscription</Button>
                </>
              ) : (
                <Button positive onClick={handleSubscribe}>Subscribe</Button>
              )}
              <PaymentCheckoutForm
                open={checkoutModalOpen}
                onClose={() => setCheckoutModalOpen(false)}
              />
            </Segment>
          )
        }
        {isAdmin && <FeatureFlags />}
      </div>
    ) : (
      <Message>You need to be logged in to view this page.</Message>
    )
  );
};

export default Account;
