export const allowFeature = (flags, featureName) => {
    const feature = flags.find(flag => flag.name === featureName);
    return feature ? feature.isEnabled : false;
  }

export const fetchFlags = async (setFlags) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
    {
      headers: {
          'Content-Type': 'application/json',
        },
    }
    );
    const data = await response.json();
    setFlags(data);
}