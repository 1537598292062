import React, { useState, useEffect } from 'react';
import { Form, Header, Icon, Accordion, Divider } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ContributionsEdit from './ContributionsEdit';

const PersonalProjectEdit = ({resumeId, activeIndex}) => {
    const [personalProjects, setPersonalProjects] = useState([]);
    const [newProject, setNewProject] = useState({
        projectTitle: '',
        demoLink: ''
    });

    const fetchPersonalProjects = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/personalProjects/${resumeId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
      
          const data = await response.json();
          setPersonalProjects(data)
      }
    
      const deletePersonalProject = async (workId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/personalProject/${workId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
      
          if (response.ok) {
            toast.success('Deleted work project successfully!');
            await fetchPersonalProjects()
          } else {
            toast.error('Failed to delete work project');
          }
      }
    
      const handleAddProject = async (e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/personalProject/${resumeId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ personalProject: newProject }),
          });
      
          if (response.ok) {
            toast.success('Added work project successfully!');
            await fetchPersonalProjects()
          } else {
            toast.error('Failed to add work project');
          }
      }

      const handleNewProjectChange = (field, value) => {
        setNewProject(prevState => ({ ...prevState, [field]: value }));
      };

      const handleExistingPersonalProjectChange = (index, field, value) => {
        let updatedExperiences = [...personalProjects]
        updatedExperiences[index][field] = value;
        setPersonalProjects
(updatedExperiences);
      };

      const handleUpdateProject = async (index, e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/personalProject/${personalProjects[index]._id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ personalProject: personalProjects[index] }),
          });
      
          if (response.ok) {
            toast.success('Updated work project successfully!');
            await fetchPersonalProjects()
          } else {
            toast.error('Failed to update work project');
          }
      }
    
      useEffect(() => {
        const fetchData = async () => {
            await fetchPersonalProjects();
          };
          fetchData();
      }, [])
    

    const renderExistingProjects = (project, index) => (
        <Form>
            <div className='container'>
                <Header as="h4">Edit Personal Project #{index+1}</Header>
                <Icon name="trash" size="large" className="icon-hover" onClick={() => deletePersonalProject(project._id)}/>
            </div>
            <Form.Input
                fluid label="Project Title" placeholder="Project Title"
                value={project.projectTitle}
                onChange={(e) => handleExistingPersonalProjectChange(index, 'projectTitle', e.target.value)}
            />
            <ContributionsEdit 
                projectId={project._id}
                modelType={'PersonalProject'}
            />
            <Form.Button positive onClick={(e) => handleUpdateProject(index, e)}>Update Personal Project #{index+1}</Form.Button>
            <Divider />
        </Form>
    );

    const renderNewProjectForm = () => {
        return (
            <Form>
                <div className='container'>
                    <Header as="h4">Add New Personal Project</Header>
                </div>
                <Form.Input
                        fluid label="Project Title" placeholder="Project Title"
                        value={newProject.projectTitle}
                        onChange={(e) => handleNewProjectChange('projectTitle', e.target.value)}
                    />
                <Form.Button positive onClick={handleAddProject}>Add</Form.Button>
            </Form>
        )
    }

    return (
    <Accordion.Content active={activeIndex === 3}>
        <Form>
            {personalProjects.map((project, index) => renderExistingProjects(project, index))}
            {renderNewProjectForm()}
        </Form>
    </Accordion.Content>
    );
};

export default PersonalProjectEdit;
