// ForgotPasswordModal.js
import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'semantic-ui-react';

const ForgotPasswordModal = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation();
    onSubmit(email);
    setOpen(false);
    setEmail('');
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button size="small" style={{ marginTop: '10px' }}>Forgot Password?</Button>}
    >
      <Modal.Header>Reset Password</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Input
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Field>
          <Button type="submit" primary>Submit</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ForgotPasswordModal;
